// Componant: Dropdown

.dropdown-menu {
  .MuiPopover-paper {
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0px 0px 32px 0px transparentize($dark-purple-500, 0.88);
    min-width: 160px;
    margin-top: 4px;
    @media screen and (max-width: 1399px) {
      min-width: 140px;
    }
    ul {
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      li {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 0;
        font-size: 14px;
        line-height: 15px;
        font-weight: 500;
        color: $dark-purple-400;
        min-height: unset;
        @media screen and (max-width: 991px) {
          font-size: 12px;
          line-height: 13px;
        }
        &:hover {
          background-color: transparent;
          color: $purple-2;
        }
        i {
          font-size: 20px;
          line-height: 20px;
          @media screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 16px;
          }
        }
        span {
          display: block;
          margin-top: 4px;
        }
      }
      hr {
        margin: 0;
        border-color: $gray-100;
      }
    }
  }

  &.dropdown-share-menu {
    .MuiBackdrop-root {
      background: transparent !important;
    }
    .MuiPopover-paper {
      min-width: unset;
      ul {
        li {
          i {
            font-size: 16px;
            @media screen and (max-width: 991px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

#country-dropdown {
  .Mui-disabled {
    background-color: $disabled-2;
  }
}

.MuiCheckbox-root {
  &.Mui-checked {
    color: $purple-2 !important;
  }
}