.custom-tabs {
    &-list {
        column-gap: 4px;
        border-bottom: 1px solid $dark-purple-100;
        margin-bottom: 24px;
        min-height: unset;
        @media screen and (max-width: 1540px) {
            margin-bottom: 20px;
        }
        @media screen and (max-width: 1399px) {
            margin-bottom: 16px;
        }
        @media screen and (max-width: 1199px) {
            margin-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
            margin-bottom: 16px;
        }
    }
    .MuiTabs-flexContainer {
        column-gap: 4px;
        @media screen and (max-width: 767px) {
            column-gap: 2PX;
        }
        @media screen and (max-width: 575px) {
            column-gap: 0;
        }
    }
    &-item {
        padding: 13px 24px;
        border-radius: 8px 8px 0 0;
        background-color: transparent;
        color: $dark-purple-500;
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        text-transform: capitalize;
        border: 0;
        min-height: unset;
        @media screen and (max-width: 1199px) {
            padding: 11px 20px;
            font-size: 16px;
            line-height: 20px;
        }
        @media screen and (max-width: 767px) {
            padding: 9px 18px;
            font-size: 14px;
            line-height: 18px;
            border-radius: 6px 6px 0 0;
        }
        @media screen and (max-width: 575px) {
            padding: 6px 12px;
            font-size: 12px;
            line-height: 16px;
        }
        &.Mui-selected {
            background-color: $purple-2;
            color: $white;
        }
    }
    .MuiTabs-indicator {
        display: none;
    }
}