// Login

.login-wrapper {
    background-image: url(../../images/login-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .login-block {
        max-width: 460px;
        width: 100%;
        background-color: $white;
        border-radius: 24px;
        padding: 44px 50px 50px;
        @media screen and (max-width: 1399px) {
            border-radius: 20px;
            padding: 40px 46px 46px;
        }
        @media screen and (max-width: 991px) {
            border-radius: 16px;
            padding: 36px 42px 42px;
        }
        @media screen and (max-width: 575px) {
            border-radius: 12px;
            padding: 32px 28px;
        }
        .brand-logo {
            width: 133px;
            margin-bottom: 26px;
            @media screen and (max-width: 1399px) {
                width: 110px;
                margin-bottom: 22px;
            }
            @media screen and (max-width: 991px) {
                width: 90px;
                margin-bottom: 18px;
            }
            @media screen and (max-width: 575px) {
                width: 70px;
                margin-bottom: 14px;
            }
        }
        .login-info {
            h1 {
                font-size: 32px;
                line-height: 38px;
                font-weight: 400;
                margin-bottom: 4px;
                color: $dark-purple-500;
                @media screen and (max-width: 1399px) {
                    font-size: 28px;
                    line-height: 34px;
                }
                @media screen and (max-width: 991px) {
                    font-size: 24px;
                    line-height: 30px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            p {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                margin-bottom: 40px;
                color: $gray-600;
                @media screen and (max-width: 1399px) {
                    margin-bottom: 36px;
                }
                @media screen and (max-width: 991px) {
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 32px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 28px;
                }
            }
            .bottom-content {
                margin-bottom: 0 !important;
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
                margin-top: 110px;
                color: $dark-purple-500;
                text-align: center;
                @media screen and (max-width: 1399px) {
                    margin-top: 90px;
                }
                @media screen and (max-width: 991px) {
                    font-size: 18px;
                    line-height: 22px;
                    margin-top: 70px;
                }
                @media screen and (max-width: 575px) {
                    font-size: 16px;
                    line-height: 20px;
                    margin-top: 50px;
                }
                span {
                    color: $purple;
                }
            }
        }
    }
}