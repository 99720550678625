@font-face {
  font-family: "MyMoneyMatters";
  src: url("MyMoneyMatters.eot?zewka0");
  src: url("MyMoneyMatters.eot?zewka0#iefix") format("embedded-opentype"),
    url("MyMoneyMatters.ttf?zewka0") format("truetype"),
    url("MyMoneyMatters.woff?zewka0") format("woff"),
    url("MyMoneyMatters.svg?zewka0#MyMoneyMatters") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="mmm-"],
[class*=" mmm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "MyMoneyMatters" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mmm-export2:before {
  content: "\e903";
}
.mmm-bell:before {
  content: "\e902";
}
.mmm-star-2:before {
  content: "\e901";
}
.mmm-export:before {
  content: "\e900";
}
.mmm-tag:before {
  content: "\f91d";
}
.mmm-filter:before {
  content: "\f91a";
}
.mmm-star:before {
  content: "\f91c";
}
.mmm-tick:before {
  content: "\f91b";
}
.mmm-bookmark:before {
  content: "\f919";
}
.mmm-clock:before {
  content: "\f918";
}
.mmm-keyboard:before {
  content: "\f917";
}
.mmm-mail:before {
  content: "\f916";
}
.mmm-color-picker:before {
  content: "\f915";
}
.mmm-phone:before {
  content: "\f914";
}
.mmm-fax:before {
  content: "\f913";
}
.mmm-search:before {
  content: "\f912";
}
.mmm-import:before {
  content: "\f911";
}
.mmm-check:before {
  content: "\f910";
}
.mmm-check-circle-fill:before {
  content: "\f90f";
}
.mmm-user-square:before {
  content: "\f90e";
}
.mmm-briefcase:before {
  content: "\f90d";
}
.mmm-logout:before {
  content: "\f90c";
}
.mmm-arrow-left:before {
  content: "\f90b";
}
.mmm-edit:before {
  content: "\f90a";
}
.mmm-calendar:before {
  content: "\f909";
}
.mmm-dollar:before {
  content: "\f908";
}
.mmm-cancel:before {
  content: "\f907";
}
.mmm-menu:before {
  content: "\f906";
}
.mmm-Facebook:before {
  content: "\f900";
}
.mmm-LinkedIn:before {
  content: "\f901";
}
.mmm-share:before {
  content: "\f902";
}
.mmm-twitter:before {
  content: "\f903";
}
.mmm-user:before {
  content: "\f904";
}
.mmm-arrow-down:before {
  content: "\f905";
}
