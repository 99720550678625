.custom-card {
  padding: 40px;
  background-color: $white;
  border-radius: 24px;
  border: 1px solid $gray-100;
  outline: 2px solid transparent;
  box-shadow: 0px 0px 32px -1px transparent;
  transition: all ease-in-out 0.5s;
  position: relative;
  z-index: 0;
  @media screen and (max-width: 1540px) {
    padding: 30px;
  }
  @media screen and (max-width: 1399px) {
    padding: 20px;
    border-radius: 20px;
  }
  @media screen and (max-width: 1199px) {
    padding: 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    border-radius: 16px;
  }
  @media screen and (max-width: 575px) {
    border-radius: 12px;
  }
  &_highlight-card {
    border-color: $coral-400;
    outline-color: $coral-400;
  }
  &.modified-card {
    border-radius: 0px !important;
    box-shadow: 0px 2px 0px 0px $purple-2 !important;
  }
  &.event-card {
    padding: 30px;
    @media screen and (max-width: 1399px) {
      padding: 20px;
    }
    @media screen and (max-width: 1199px) {
      padding: 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-image: url(../../images/vector-5.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: -1;
    }
    &.event-card-1 {
      &::before {
        background-image: url(../../images/vector-5.svg);
      }
      .card-more-content {
        .card-category {
          background-color: $purple-100;
        }
      }
    }
    &.event-card-2 {
      &::before {
        background-image: url(../../images/vector-6.svg);
      }
      .card-more-content {
        .card-category {
          background-color: $purple-1-100;
        }
      }
    }
    &.event-card-3 {
      &::before {
        background-image: url(../../images/vector-7.svg);
      }
      .card-more-content {
        .card-category {
          background-color: $purple-2-300;
        }
      }
    }
    &.event-card-4 {
      &::before {
        background-image: url(../../images/webinar.svg);
        background-size: 30% !important;
        background-position: bottom right !important;
      }
      .card-more-content {
        .card-category {
          background-color: $purple-100;
        }
      }
    }
    .card-image {
      background-image: url(../../images/webinar.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
    h2 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 58px;
      max-width: 380px;
      @media screen and (max-width: 1399px) {
        min-height: 50px;
      }
      @media screen and (max-width: 767px) {
        min-height: 42px;
      }
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 21px;
      line-height: 21px;
      color: $gray-600;
      max-width: 380px;
      margin-bottom: 12px;
      @media screen and (max-width: 767px) {
        min-height: 19px;
        line-height: 19px;
      }
    }
  }
  &.hignlight-card {
    border: 3px solid $coral-400;
  }
  .card-header {
    column-gap: 40px;
    row-gap: 16px;
    @media (max-width: 575px) {
      width: 100%;
      column-gap: 20px;
    }
    .card-action {
      @media screen and (max-width: 575px) {
        order: -1;
      }
      &.autoInput_textwrapper {
        column-gap: 16px;
        row-gap: 12px;
        @media (max-width: 575px) {
          width: 100%;
          order: unset;
        }
        .MuiFormControl-root {
          margin: 0;
          @media (max-width: 575px) {
            width: 100%;
          }
        }
        .MuiFormLabel-root {
          font-size: 16px;
          line-height: 1em;
          overflow: unset;
          transform: translate(14px, 16px) scale(1) !important;
          color: $dark-purple-400 !important;
          &.MuiFormLabel-filled {
            display: none;
          }
        }
        .MuiInputBase-input {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          color: $dark-purple-400;
          text-align: left;
          height: 44px;
          padding: 12px 12px;
        }
        svg {
          color: $dark-purple-400;
        }
        .btn {
          border-radius: 8px;
          padding: 14px 24px;
          height: 44px;
          @media (max-width: 575px) {
            width: calc(50% - 8px);
          }
          @media (max-width: 375px) {
            padding: 14px 10px;
          }
        }
      }
      .MuiInputBase-formControl {
        border: 1px solid $dark-purple-400;
        height: 44px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        min-width: 180px;
        width: auto;
        font-size: 16px;
        font-weight: 600;
        @media (max-width: 575px) {
          min-width: 100%;
        }
        &::placeholder {
          display: block;
          opacity: 1 !important;
          color: $dark-purple-400;
          -webkit-text-fill-color: $dark-purple-400;
        }
        &:hover {
          border: 1px solid $dark-purple-400;
        }
        .MuiAutocomplete-input {
          padding-top: 0px;
          padding-bottom: 0px;
          font-size: 16px;
          line-height: 23px;
          font-weight: 500;
          color: $dark-purple-400;
          text-transform: capitalize;
        }
        fieldset {
          border: 0px;
          top: 0;
        }
      }
      .autoInput_text {
        .MuiInputBase-formControl {
          border: 1px solid $dark-purple-400;
          height: 44px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          min-width: 180px;
          width: auto;
          font-size: 16px;
          font-weight: 600;
          @media (max-width: 767px) {
            margin-bottom: 15px;
          }
          &::placeholder {
            display: block;
            opacity: 1 !important;
            color: $dark-purple-400;
            -webkit-text-fill-color: $dark-purple-400;
          }
          &:hover {
            border: 1px solid $dark-purple-400;
          }
        }
      }
      .btn {
        @media screen and (max-width: 575px) {
          padding: 9px 7px;
          border-radius: 50%;
          height: 36px;
        }
      }
    }
  }
  h1 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    color: $dark-purple;
    margin-bottom: 4px;
    @media screen and (max-width: 1399px) {
      font-size: 28px;
      line-height: 34px;
    }
    @media screen and (max-width: 991px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media screen and (max-width: 575px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: $dark-purple-300;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  h2,
  h3 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    color: $dark-purple-400;
    margin-bottom: 4px;
    @media screen and (max-width: 1399px) {
      font-size: 20px;
      line-height: 25px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: $dark-purple;
    margin-bottom: 4px;
    text-transform: capitalize;
    @media screen and (max-width: 1399px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .card-more-content {
    column-gap: 24px;
  }

  .card-date {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: $gray-600;
    column-gap: 6px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 19px;
    }
    i {
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  .card-category {
    height: 27px;
    min-width: 86px;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $purple-100;
    color: $white;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 19px;
    }
  }
  .tooltip-btn {
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: $white;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 14px;
    }
    &.hignlight-tooltip {
      border-color: $purple;
      color: $purple;
    }
    &.booked-tooltip {
      border-color: $green-200;
      color: $green-200;
      font-size: 12px;
      bottom: 12px;
      top: auto;
    }
  }
  &.wizard-card {
    padding: 48px 60px;
    @media screen and (max-width: 1399px) {
      padding: 40px 52px;
    }
    @media screen and (max-width: 991px) {
      padding: 32px 36px;
    }
    @media screen and (max-width: 767px) {
      padding: 30px;
    }
    @media screen and (max-width: 575px) {
      padding: 24px;
    }
  }
  .wizard-steps {
    min-width: 270px;
    margin-left: -60px;
    margin-top: -48px;
    margin-bottom: -48px;
    padding: 48px 0;
    background-color: $disabled-1;
    border-right: 1px solid $dark-purple-100;
    @media screen and (max-width: 1399px) {
      margin-left: -52px;
      margin-top: -40px;
      margin-bottom: -40px;
      padding: 40px 0;
    }
    @media screen and (max-width: 991px) {
      margin-left: -36px;
      margin-top: -32px;
      margin-bottom: -32px;
      padding: 32px 0;
    }
    .wizard-step_wrapper {
      row-gap: 8px;
      padding: 0 16px;
      max-height: 808px;
      overflow-y: auto;
      @media screen and (max-width: 1399px) {
        padding: 0 28px;
        max-height: 796px;
      }
      @media screen and (max-width: 1199px) {
        max-height: 774px;
      }
      @media screen and (max-width: 1089px) {
        max-height: 890px;
      }
      @media screen and (max-width: 991px) {
        padding: 0 24px;
        max-height: 820px;
      }
      @media screen and (max-width: 862px) {
        max-height: 857px;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: $dark-purple-100;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $dark-purple-500;
        border-radius: 5px;
      }
    }
    .wizard-step-label {
      padding: 0;
      .MuiStepLabel-label {
        padding: 12px 16px;
        border-radius: 8px;
        background-color: transparent;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: $dark-purple-700;
        @media screen and (max-width: 991px) {
          padding: 10px 14px;
          font-size: 14px;
          border-radius: 6px;
        }
        &.Mui-active {
          background-color: $purple-2;
          color: $white;
        }
      }
    }
    .MuiStepLabel-iconContainer,
    .MuiStepConnector-vertical {
      display: none;
    }
  }
  .wizard-steps-content {
    padding-left: 60px;
    @media screen and (max-width: 1399px) {
      padding-left: 52px;
    }
    @media screen and (max-width: 991px) {
      padding-left: 36px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 0px;
    }
    div[role="tabpanel"] {
      > .MuiBox-root {
        padding: 0;
      }
    }
  }
  .custom-progress-wrapper {
    column-gap: 26px;
    @media screen and (max-width: 575px) {
      column-gap: 16px;
    }
    img {
      @media screen and (max-width: 991px) {
        width: 36px;
      }
      @media screen and (max-width: 575px) {
        width: 28px;
      }
    }
  }
  .custom-progress {
    column-gap: 5px;
    @media screen and (max-width: 575px) {
      column-gap: 4px;
    }
    .progress-bar {
      height: 8px;
      background: $dark-purple-100;
      width: 100%;
      @media screen and (max-width: 767px) {
        height: 6px;
      }
      &.active {
        background: $purple-2;
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .wizard-title_wrapper {
    margin-bottom: 16px;
    @media screen and (max-width: 1399px) {
      margin-bottom: 14px;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 12px;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 10px;
    }
    .wizard-title {
      column-gap: 20px;
      h2 {
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
        color: $dark-purple;
        @media screen and (max-width: 1399px) {
          font-size: 28px;
          line-height: 32px;
        }
        @media screen and (max-width: 991px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }
        @media screen and (max-width: 575px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      .wizard-step-number {
        font-size: 16px;
        line-height: inherit;
        color: $dark-purple;
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
        @media screen and (max-width: 575px) {
          font-size: 12px;
        }
      }
    }
  }
  .wizard-block {
    margin-right: -60px;
    padding-right: 60px;
    max-height: 562px;
    overflow-y: auto;
    @media screen and (max-width: 1399px) {
      margin-right: -52px;
      padding-right: 52px;
    }
    @media screen and (max-width: 1089px) {
      max-height: 662px;
    }
    @media screen and (max-width: 991px) {
      margin-right: -36px;
      padding-right: 36px;
      max-height: 507px;
    }
    @media screen and (max-width: 916px) {
      max-height: 592px;
    }
    @media screen and (max-width: 845px) {
      max-height: 628px;
    }
    @media screen and (max-width: 767px) {
      margin-right: -30px;
      padding-right: 30px;
      max-height: 481px;
    }
    @media screen and (max-width: 654.99px) {
      max-height: 582px;
    }
    @media screen and (max-width: 575px) {
      margin-right: -24px;
      padding-right: 24px;
      max-height: 445px;
    }
    @media screen and (max-width: 570px) {
      max-height: 520px;
    }
    @media screen and (max-width: 451px) {
      max-height: 536px;
    }
    @media screen and (max-width: 375px) {
      max-height: 568px;
    }
    @media screen and (max-width: 350px) {
      max-height: 659px;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $dark-purple-100;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dark-purple-500;
      border-radius: 5px;
    }
    p {
      font-weight: 400;
      color: $dark-purple-600;
      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }
  }
  .wizard-content_wrapper {
    row-gap: 32px;
    overflow: hidden;
    @media screen and (max-width: 1399px) {
      row-gap: 28px;
    }
    @media screen and (max-width: 991px) {
      row-gap: 24px;
    }
    @media screen and (max-width: 575px) {
      row-gap: 20px;
    }
    @for $i from 1 through 30 {
      .fade-in:nth-child(#{$i}) {
        @include fadeInUp(((($i - 1) * 0.1s) + 0.5s));
      }
    }
    .form-control {
      row-gap: 16px;
      @media screen and (max-width: 1399px) {
        row-gap: 14px;
      }
      @media screen and (max-width: 991px) {
        row-gap: 12px;
      }
      @media screen and (max-width: 575px) {
        row-gap: 10px;
      }
    }
    .form-label {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      color: $dark-purple;
      display: flex;
      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 17px;
      }
      @media screen and (max-width: 575px) {
        font-size: 12px;
        line-height: 15px;
      }
      span {
        min-width: 20px;
        display: inline-block;
        @media screen and (max-width: 991px) {
          min-width: 16px;
        }
      }
    }
    // .custom-radio-group {
    //   column-gap: 24px;
    //   margin-left: 20px;
    //   @media screen and (max-width: 1399px) {
    //     column-gap: 20px;
    //   }
    //   @media screen and (max-width: 991px) {
    //     column-gap: 16px;
    //     margin-left: 16px;
    //   }
    //   @media screen and (max-width: 575px) {
    //     column-gap: 12px;
    //   }
    // }
    // .custom-radio {
    //   margin: 0;
    //   .MuiRadio-root {
    //     display: none;
    //     &.Mui-checked {
    //       & + .MuiFormControlLabel-label {
    //         background: linear-gradient(180deg, $purple-300, $purple-2);
    //       }
    //     }
    //   }
    //   .MuiFormControlLabel-label {
    //     height: 43px;
    //     min-width: 74px;
    //     border-radius: 8px;
    //     display: flex;
    //     align-items: end;
    //     justify-content: center;
    //     background: $dark-purple;
    //     color: $white;
    //     font-size: 16px;
    //     line-height: normal;
    //     text-transform: capitalize;
    //     font-weight: 400;
    //     padding: 12px 20px;
    //     @media screen and (max-width: 991px) {
    //       border-radius: 6px;
    //       height: 39px;
    //       min-width: 68px;
    //       font-size: 14px;
    //     }
    //     @media screen and (max-width: 575px) {
    //       border-radius: 4px;
    //       height: 32px;
    //       min-width: 60px;
    //       font-size: 12px;
    //     }
    //   }
    // }
    .MuiFormControlLabel-root {
      column-gap: 8px;
      margin-left: 0;
    }
    .MuiButtonBase-root {
      padding: 0;
    }
  }
  .action-btns.step-sctions {
    flex-wrap: wrap;
    row-gap: 12px;
    > .btn {
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
  }
  .action-btns.step-sctions,
  .step-sctions-right {
    column-gap: 16px;
    @media screen and (max-width: 1399px) {
      column-gap: 14px;
    }
    @media screen and (max-width: 991px) {
      column-gap: 12px;
    }
    @media screen and (max-width: 575px) {
      column-gap: 10px;
    }
  }
  .step-sctions-right {
    @media screen and (max-width: 575px) {
      order: -1;
      width: 100%;
    }
    .btn-purple {
      @media screen and (max-width: 575px) {
        flex: 1 1 auto;
      }
    }
  }
}

.custom-card-action {
  position: relative;
  z-index: 2;
  width: calc(33% - 33px);
  @media screen and (max-width: 1539px) {
    width: calc(33% - 28px);
  }
  @media screen and (max-width: 1399px) {
    width: calc(33% - 23px);
  }
  @media screen and (max-width: 1199px) {
    width: calc(33% - 17px);
  }
  @media screen and (max-width: 991px) {
    width: calc(50% - 15px);
  }
  @media screen and (max-width: 767px) {
    width: calc(50% - 10px);
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  &:hover {
    .MuiCardActionArea-focusHighlight {
      display: none;
    }
  }
  .custom-card {
    &:hover {
      box-shadow: 0px 0px 32px -1px #00000040;
    }
    h2 {
      line-height: 32px;
      min-height: 64px;
      color: $dark-purple;
      @media screen and (max-width: 1399px) {
        line-height: 28px;
        min-height: 56px;
      }
      @media screen and (max-width: 767px) {
        line-height: 24px;
        min-height: 48px;
      }
      @media screen and (max-width: 575px) {
        min-height: unset;
      }
    }
  }
}
.custom-card.event-card {
  .card-webinars {
    h4 {
      font-size: 32px;
      line-height: 38px;
      color: $dark-purple-400;
      @media screen and (max-width: 1399px) {
        font-size: 28px;
        line-height: 32px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media screen and (max-width: 575px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    span {
      font-size: 24px;
      line-height: 29px;
      color: $dark-purple-400;
      @media screen and (max-width: 1399px) {
        font-size: 22px;
        line-height: 27px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 23px;
      }
      @media screen and (max-width: 575px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .card-rating {
    row-gap: 4px;
    @media screen and (max-width: 575px) {
      row-gap: 2px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: $purple-2;
    }
    h4 {
      font-size: 48px;
      line-height: 54px;
      font-weight: 400;
      color: $purple-2;
      @media screen and (max-width: 1399px) {
        font-size: 40px;
        line-height: 46px;
      }
      @media screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 36px;
      }
      @media screen and (max-width: 575px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}

.custom-application {
  font-family: "elza" !important;

  h1,
  p,
  h2,
  th,
  tr {
    font-family: "Elza";
  }

  th {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    padding: 16px 0px 16px 16px;
    width: 166px;
  }

  tr {
    padding: 10px 0px 10px 16px;
    font-family: Elza;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    &:hover {
      background-color: #18112f33;
    }
  }

  .plan-type {
    gap: 2px;
    display: flex;
    flex-direction: column;
  }

  .plan-type p {
    &:first-child {
      width: 90px;
      padding-top: 3px;
      height: 19px;
      border-radius: 2px 0px 0px 0px;
      color: white;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      text-align: center;
      background-color: #7336ae;
    }
    &:nth-child(2) {
      width: 90px;
      padding-top: 3px;
      height: 19px;
      border-radius: 2px 0px 0px 0px;
      color: #18112f;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      text-align: center;
      background-color: #18112f1a;
    }
  }

  .plan-status {
    display: block;
    width: 90px;
    height: auto;
    border-radius: 2px 0px 0px 0px;
    font-size: 12px;
    padding-top: 3px;
    font-weight: 400;
    line-height: 14.4px;
    color: white;
    text-align: center;
  }

  .card-header {
    max-height: 770px;
    .card-content {
      display: flex;
      justify-content: space-between;
      padding: 26px 20px !important;

      hr {
        width: 32px;
        height: 0px;
        top: 20px;
        left: 938px;
        gap: 0px;
        border: 1px 0px 0px 0px;
        opacity: 0px;
        transform: rotate(90deg);
      }
    }

    .card-action button {
      border: none;
    }
    &-heading {
      font-family: "elza" !important;
      h1 {
        font-size: 16px;
        font-weight: 600;
        color: $purple-v2;
        line-height: 19.76px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }

  .filter-card,
  .table-card {
    min-height: 770px;
    max-height: 770px;
    overflow-y: auto;
    @media screen and (max-width: 575px) {
      min-height: 400px;
      max-height: 400px;
    }
  }
}
