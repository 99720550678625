.custom-pagination {
    ul {
        margin-top: 18px;
        justify-content: center;
        column-gap: 8px;
        li {
            button {
                margin: 0;
                width: 32px;
                height: 32px;
                font-size: 14px;
                line-height: 15px;
                font-weight: 400;
                border-radius: 8px;
                background-color: $disabled-1;
                color: $dark-purple;
                &.Mui-selected {
                    color: $white;
                    background-color: $purple-2;
                }
            }
            &:first-child, &:last-child {
                button {
                    background-color: transparent;
                    color: $dark-purple-300;
                }
            }
        }
    }
}