// Componant: Header

.top-header {
  padding: 20px 0 !important;
  background: transparent;
  box-shadow: none;
  transition: all ease-in-out 0.5s;
  position: relative;
  @media screen and (max-width: 1399px) {
    padding: 16px 0 !important;
  }
  @media screen and (max-width: 767px) {
    padding: 16px 8px !important;
  }
  &.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    background: $dark-purple;
    .navbar-brand {
      .dark-logo {
        display: none;
      }
      .light-logo {
        display: block;
      }
      .hamburger-menu {
        color: $white;
      }
    }
    .nav-list {
      .nav-item {
        .nav-link {
          color: $white;
          &.active,
          &:hover {
            border-color: $white;
          }
          i {
            color: $white;
          }
        }
      }
    }
    .navbar-menu-right {
      .profile-dropdown {
        color: $white;
      }
    }
    @media screen and (max-width: 600px) {
      .nav-list {
        .nav-item {
          .nav-link {
            color: black;
            i {
              color: black; 
            }
          }
        }
      }
    }
  }
}

.navbar-brand {
  column-gap: 20px;
  @media screen and (max-width: 375px) {
    column-gap: 16px;
  }
  .hamburger-menu {
    color: $dark-purple;
    font-size: 22px;
    background-color: transparent;
    @media screen and (max-width: 375px) {
      font-size: 20px;
    }
  }
  .MuiButtonBase-root {
    &:hover {
      background: transparent;
    }
  }
  .dark-logo,
  .light-logo {
    @media screen and (max-width: 1399px) {
      width: 90px;
    }
    @media screen and (max-width: 991px) {
      width: 75px;
    }
    @media screen and (max-width: 575px) {
      width: 65px;
    }
    @media screen and (max-width: 375px) {
      width: 55px;
    }
  }
  .dark-logo {
    display: block;
  }
  .light-logo {
    display: none;
  }
}
.navbar-menu-wrapper {
  @media screen and (max-width: 991px) {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    bottom: 0;
    right: auto;
    margin: auto;
    background: #fff;
    transition: all ease-in-out 0.6s;
    z-index: 99;
  }
  &.menu-wrapper-collapsed {
    @media screen and (max-width: 991px) {
      left: 0px;
    }
  }
  .close-menu {
    padding: 30px;
    img {
      @media screen and (max-width: 991px) {
        width: 75px;
      }
      @media screen and (max-width: 575px) {
        width: 65px;
      }
      @media screen and (max-width: 375px) {
        width: 55px;
      }
    }
    a {
      display: block;
      text-align: right;
      color: $dark-purple;
      font-size: 26px;
    }
  }
  .nav-list {
    column-gap: 4px;
    @media screen and (max-width: 1399px) {
      column-gap: 2px;
    }
    .nav-item {
      width: unset;
      .nav-link {
        padding: 4px 25px;
        border-radius: 8px;
        height: 43px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: unset;
        border: 1px solid transparent;
        color: $dark-purple;
        background-color: transparent;
        transition: all ease-in-out 0.5s;
        column-gap: 12px;
        @media screen and (max-width: 1399px) {
          padding: 10px 20px;
          font-size: 14px;
          line-height: 17px;
          border-radius: 9px;
          height: auto;
        }
        @media screen and (max-width: 991px) {
          width: 100%;
          border: 0;
          border-left: 5px solid transparent;
          border-radius: 0;
          padding: 12px 25px;
          padding-left: 21px;
          justify-content: start;
          border-bottom: 1px solid $disabled-1;
        }
        &.active,
        &:hover {
          color: $white;
          border-color: $dark-purple;
          background-color: $dark-purple;
          @media screen and (max-width: 991px) {
            color: $dark-purple;
            background-color: transparent;
            border-color: $disabled-1;
            border-left-color: $dark-purple;
          }
          i {
            color: $white;
            @media screen and (max-width: 991px) {
              color: $purple;
            }
          }
        }
        &:hover {
          i {
            animation: bell-animation 3s 0.7s ease-in-out infinite;
          }
        }
        span {
          display: flex;
          padding-top: 4px;
        }
        i {
          font-size: 24px;
          line-height: 24px;
          color: $purple;
          transform: rotate(0deg);

          @media screen and (max-width: 1399px) {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@include keyframes(bell-animation) {
  0%,
  84%,
  100% {
    transform: rotate(0deg);
  }
  4% {
    transform: rotate(35deg);
  }
  8% {
    transform: rotate(-33deg);
  }
  12% {
    transform: rotate(37deg);
  }
  16% {
    transform: rotate(-35deg);
  }
  20% {
    transform: rotate(35deg);
  }
  24% {
    transform: rotate(-33deg);
  }
  28% {
    transform: rotate(30deg);
  }
  32% {
    transform: rotate(-27deg);
  }
  36% {
    transform: rotate(25deg);
  }
  40% {
    transform: rotate(-22deg);
  }
  44% {
    transform: rotate(20deg);
  }
  48% {
    transform: rotate(-16deg);
  }
  52% {
    transform: rotate(15deg);
  }
  56% {
    transform: rotate(-12deg);
  }
  60% {
    transform: rotate(10deg);
  }
  64% {
    transform: rotate(-6deg);
  }
  68% {
    transform: rotate(5deg);
  }
  72% {
    transform: rotate(-3deg);
  }
  76% {
    transform: rotate(1deg);
  }
  80% {
    transform: rotate(-1deg);
  }
}

.notification-mobile {
  width: 40px;
  height: 40px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $dark-purple-400;
  font-size: 20px;
  color: $dark-purple;
  background-color: $white;
  @media screen and (max-width: 1399px) {
    width: 36px;
    height: 36px;
    border-radius: 14px;
    font-size: 17px;
  }
  @media screen and (max-width: 991px) {
    border-radius: 12px;
  }
  @media screen and (max-width: 375px) {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    font-size: 16px;
  }
  &:hover {
    i {
      animation: bell-animation 3s 0.7s ease-in-out infinite;
    }
  }
}

.navbar-menu-right {
  column-gap: 16px;
  @media screen and (max-width: 1399px) {
    column-gap: 12px;
  }
  @media screen and (max-width: 991px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 575px) {
    column-gap: 12px;
  }
  @media screen and (max-width: 375px) {
    column-gap: 8px;
  }
  .btn.btn-md {
    column-gap: 24px;
    padding: 10px 14px;
    @media screen and (max-width: 1399px) {
      column-gap: 18px;
      padding: 8px 12px;
    }
    @media screen and (max-width: 991px) {
      border-width: 0px;
      background-color: $white !important;
      min-width: unset;
      padding: 0px !important;
      height: auto !important;
      border-radius: 12px;
      overflow: hidden;
    }
    @media screen and (max-width: 375px) {
      border-radius: 8px;
    }
    span {
      column-gap: 24px;
      @media screen and (max-width: 1399px) {
        column-gap: 18px;
      }
    }
    .org-logo {
      img {
        border-radius: 12px;
        width: 36px;
        height: 36px;
        object-fit: contain;
        object-position: center;
        display: block;
        padding: 3px;
        border: 3px solid $blue;
        @media screen and (max-width: 375px) {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          border-width: 2px;
        }
      }
    }
  }
  .overflow-org-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    // display: block !important;
    line-height: normal;
  }
  .profile-dropdown {
    column-gap: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $dark-purple;
    text-transform: unset;
    padding: 0;
    @media screen and (max-width: 1399px) {
      column-gap: 6px;
      font-size: 12px;
      line-height: 15px;
    }
    &:hover {
      background-color: transparent;
    }
    i {
      width: 40px;
      height: 40px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $dark-purple-400;
      font-size: 20px;
      color: $dark-purple;
      background-color: $white;
      @media screen and (max-width: 1399px) {
        width: 36px;
        height: 36px;
        border-radius: 14px;
        font-size: 17px;
      }
      @media screen and (max-width: 991px) {
        border-radius: 12px;
      }
      @media screen and (max-width: 375px) {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }
}
