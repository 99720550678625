.retirement-block {
  background: $purple-4-200;
  // padding: 24px;
  border-radius: 16px;
  column-gap: 24px;
  margin-bottom: 32px;
  @media screen and (max-width: 991px) {
    row-gap: 12px;
    padding: 20px;
    margin-bottom: 28px;
    border-radius: 12px;
  }
  @media screen and (max-width: 767px) {
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 8px;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 8px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 34px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media screen and (max-width: 575px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  p {
    line-height: 24px;
    font-weight: 400;
    color: $gray-600;
    @media screen and (max-width: 767px) {
      line-height: 19px;
    }
  }
  &_category {
    column-gap: 8px;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.event-detail {
  &_block {
    border-bottom: 1px solid $dark-purple-100;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 991px) {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    &.booking_block {
      .event-detail_card {
        column-gap: 60px;
        @media screen and (max-width: 1399.9px) {
          column-gap: 25px;
        }
        @media screen and (max-width: 1199px) {
          column-gap: 25px;
        }
        @media screen and (max-width: 991px) {
          row-gap: 20px;
        }
        @media screen and (max-width: 767px) {
          row-gap: 16px;
        }
        .event-card_info {
          &:first-child {
            max-width: 215px;
            width: 100%;
            @media screen and (max-width: 1399.9px) {
              max-width: 200px;
            }
            @media screen and (max-width: 1199px) {
              max-width: 170px;
            }
            @media screen and (max-width: 991px) {
              width: calc(50% - 20px);
              max-width: unset;
            }
            @media screen and (max-width: 575px) {
              width: 100%;
            }
          }
          &:nth-child(3) {
            max-width: 130px;
            width: 100%;
            @media screen and (max-width: 1199px) {
              max-width: 110px;
            }
            @media screen and (max-width: 991px) {
              width: calc(50% - 20px);
              max-width: unset;
            }
            @media screen and (max-width: 575px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &_card {
    column-gap: 100px;
    padding: 24px;
    border-radius: 16px;
    background: $white;
    box-shadow: 0px 0px 32px -1px transparent;
    transition: all ease-in-out 0.5s;
    @media screen and (max-width: 1540px) {
      column-gap: 65px;
    }
    @media screen and (max-width: 1399px) {
      column-gap: 40px;
    }
    @media screen and (max-width: 1199px) {
      padding: 20px;
      border-radius: 12px;
    }
    @media screen and (max-width: 991px) {
      row-gap: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
      row-gap: 16px;
      border-radius: 8px;
    }
    &:hover {
      box-shadow: 0px 0px 32px -1px rgba($dark-purple-500, 0.15);
    }
    h3 {
      font-size: 14px;
      line-height: 21px;
      color: $gray-300;
      text-transform: uppercase;
      @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 2px;
      }
    }
    p {
      line-height: 21px;
      color: $gray-600;
      @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 19px;
      }
    }
    .event-card_info {
      @media screen and (max-width: 991px) {
        width: calc(50% - 20px);
      }
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
    .event-card_action-tags {
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
    .event-card_tag-wrapper {
      @media screen and (max-width: 991px) {
        border-right: 1px solid $gray-100;
        margin-right: 16px;
      }
      @media screen and (max-width: 575px) {
        border-right: 0px;
        margin-right: 0px;
        width: 100%;
        margin-bottom: 16px;
      }
    }
    .event-card_tag {
      background: $purple-3-500;
      padding: 9px 11px;
      border-radius: 8px;
      margin-right: 45px;
      height: 38px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: $white;
      column-gap: 8px;
      position: relative;
      @media screen and (max-width: 1199px) {
        font-size: 12px;
        line-height: 20px;
        margin-right: 40px;
      }
      @media screen and (max-width: 575px) {
        width: max-content;
      }
      .triangle {
        position: absolute;
        right: -20px;
        top: 23%;
        background-color: $purple-3-500;
        text-align: left;
        transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
        width: 20px;
        height: 20px;
        border-top-right-radius: 30%;
        @media screen and (max-width: 1399px) {
          top: 24%;
        }
        &:after,
        &:before {
          content: "";
          position: absolute;
          background-color: inherit;
          width: 20px;
          height: 20px;
          border-top-right-radius: 30%;
        }
        &:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
            translate(0, -50%);
        }
        &:after {
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
            translate(50%);
        }
      }
      i {
        font-size: 17px;
        line-height: 17px;
        @media screen and (max-width: 1199px) {
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
    .event-card_action {
      border-left: 1px solid $gray-100;
      padding-left: 20px;
      @media screen and (max-width: 1199px) {
        padding-left: 16px;
      }
      @media screen and (max-width: 991px) {
        padding-left: 0;
        border-left: 0;
        // width: 100%;
      }
      .btn {
        padding: 11px 14px;
        min-width: 142px;
        @media screen and (max-width: 1199px) {
          min-width: 132px;
        }
      }
    }
  }
}
