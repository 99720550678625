.product-list-section {
  position: relative;
  padding: 70px 0 100px;
  overflow: hidden;
  @media screen and (max-width: 1399px) {
    padding: 50px 0 80px;
  }
  @media screen and (max-width: 991px) {
    padding: 30px 0 56px;
  }
  @media screen and (max-width: 767px) {
    padding: 16px 8px 40px;
  }
  &::after {
    content: "";
    display: block;
    width: 687px;
    height: 779px;
    background: url(../../images/vector-4.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -100px;
    right: -124px;
    z-index: -1;
    transform: rotate(148deg);
    @media screen and (max-width: 1399px) {
      width: 540px;
      height: 630px;
      bottom: -60px;
      right: -100px;
    }
    @media screen and (max-width: 991px) {
      width: 500px;
      height: 540px;
      right: -80px;
    }
    @media screen and (max-width: 767px) {
      width: 400px;
      height: 450px;
      right: -120px;
      bottom: -80px;
    }
  }
  .product-list-row {
    row-gap: 70px;
    column-gap: 46px;
    @media screen and (max-width: 1540px) {
      row-gap: 50px;
      column-gap: 26px;
    }
    @media screen and (max-width: 1399px) {
      row-gap: 30px;
      column-gap: 6px;
    }
    @media screen and (max-width: 991px) {
      row-gap: 24px;
      column-gap: 0;
    }
    @media screen and (max-width: 767px) {
      row-gap: 16px;
    }
  }
  .product-list-col {
    max-width: 457px;
    @media screen and (max-width: 1540px) {
      max-width: 422px;
    }
    @media screen and (max-width: 1399px) {
      max-width: 376px;
    }
    @media screen and (max-width: 1199px) {
      max-width: 38%;
    }
    @media screen and (max-width: 991px) {
      max-width: 50%;
    }
    @media screen and (max-width: 767px) {
      max-width: 60%;
    }
    @media screen and (max-width: 575px) {
      max-width: 100%;
    }
    &:nth-child(odd) {
      .product-card {
        .card-content {
          .card-image {
            &::before {
              background-image: url(../../images/vector-3.svg);
            }
          }
        }
      }
    }
  }
  .product-card {
    padding: 40px;
    background-color: $white;
    border-radius: 24px;
    border: 1px solid $gray-100;
    box-shadow: 0px 0px 32px -1px transparent;
    transition: all ease-in-out 0.5s;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 1540px) {
      padding: 30px;
    }
    @media screen and (max-width: 1399px) {
      padding: 20px;
    }
    @media screen and (max-width: 1199px) {
      padding: 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
    &:hover {
      box-shadow: 0px 0px 32px -1px transparentize($dark-purple-500, 0.85);
      .card-content {
        .card-header-action {
          a {
            color: $purple-2;
          }
        }
      }
    }
    .card-content {
      .card-header-action {
        a {
          color: $dark-purple-300;
          font-size: 24px;
          transition: all ease-in-out 0.5s;
          position: absolute;
          top: 24px;
          right: 24px;
          z-index: 1;
          @media screen and (max-width: 1399px) {
            top: 16px;
            right: 16px;
            font-size: 16px;
          }
        }
      }
      .card-image {
        padding-bottom: 5px;
        min-height: 186px;
        position: relative;
        margin-bottom: 26px;
        @media screen and (max-width: 1399px) {
          min-height: 156px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
          min-height: 130px;
          margin-bottom: 16px;
        }
        &::before {
          content: "";
          width: 230px;
          height: 100%;
          background-image: url(../../images/vector-2.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: -1;
          @media screen and (max-width: 1399px) {
            width: 180px;
          }
          @media screen and (max-width: 767px) {
            width: 150px;
          }
        }
        img {
          width: 110px;
          height: 90px;
          object-fit: contain;
          @media screen and (max-width: 1399px) {
            width: 90px;
            height: 70px;
          }
          @media screen and (max-width: 767px) {
            width: 80px;
            height: 60px;
          }
        }
      }
      p {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: $gray-400;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 42px;
        @media screen and (max-width: 1540px) {
          font-size: 15px;
          line-height: 20px;
          min-height: 40px;
        }
        @media screen and (max-width: 1399px) {
          font-size: 14px;
          line-height: 19px;
          min-height: 38px;
        }
        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 16px;
          min-height: unset;
        }
      }
      .price-subscription {
        column-gap: 16px;
        margin: 20px 0;
        @media screen and (max-width: 1399px) {
          margin: 16px 0;
        }
        h3 {
          color: $primary-blue-700;
          margin-bottom: 4px;
          column-gap: 6px;
          @media screen and (max-width: 1399px) {
            column-gap: 3px;
          }
          i {
            font-size: 17px;
            @media screen and (max-width: 1399px) {
              font-size: 14px;
            }
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
        p {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: $gray-400;
          min-height: 35px;
          @media screen and (max-width: 1540px) {
            font-size: 13px;
            line-height: 16px;
            min-height: 32px;
          }
          @media screen and (max-width: 1399px) {
            font-size: 12px;
            line-height: 15px;
            min-height: 30px;
          }
          @media screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 14px;
          }
        }
        .subscription {
          h3 {
            color: $green;
          }
        }
      }
      .coming-soon {
        margin: 20px 0;
        min-height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1399px) {
          min-height: 59px;
          margin: 16px 0;
        }
        p {
          font-size: 24px;
          line-height: 29px;
          font-weight: 300;
          color: $gray-300;
          @media screen and (max-width: 1399px) {
            font-size: 20px;
            line-height: 25px;
          }
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
