.profile-card {
  border-color: $dark-purple-100;
  box-shadow: 0px 0px 32px -1px transparentize($dark-purple-500, 0.85);
  .profile-content {
    column-gap: 80px;
    @media screen and (max-width: 1399px) {
      column-gap: 60px;
    }
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      row-gap: 40px;
    }
    .profile-left-content {
      flex: 0 0 380px;
      max-width: 380px;
      padding: 32px;
      @media screen and (max-width: 1399px) {
        flex: 0 0 300px;
        max-width: 300px;
        padding: 24px;
      }
      @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin: auto;
      }
      .card-image {
        padding-bottom: 5px;
        min-height: 177px;
        position: relative;
        margin-bottom: 24px;
        @media screen and (max-width: 1399px) {
          min-height: 160px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 991px) {
          min-height: 145px;
          margin-bottom: 16px;
        }
        &::before {
          content: "";
          width: 205px;
          height: 100%;
          background-image: url(../../images/vector-3.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: -1;
          @media screen and (max-width: 1399px) {
            width: 180px;
          }
          @media screen and (max-width: 991px) {
            width: 150px;
          }
        }
        img {
          width: 122px;
          height: 70px;
          object-fit: contain;
          @media screen and (max-width: 1399px) {
            width: 90px;
            height: 50px;
          }
          @media screen and (max-width: 991px) {
            width: 70px;
            height: 40px;
          }
        }
      }
      .custom-divider {
        width: 90px;
        margin: 32px auto;
        border-color: $dark-purple-100;
        @media screen and (max-width: 991px) {
          width: 70px;
          margin: 24px auto;
        }
        @media screen and (max-width: 767px) {
          width: 50px;
          margin: 16px auto;
        }
      }
      p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: $dark-purple-400;
        @media screen and (max-width: 1399px) {
          font-size: 14px;
          line-height: 20px;
        }
        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .custom-form {
        .form-group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .MuiFormControl-root {
            width: unset;
          }
          .MuiInputBase-formControl {
            padding: 0;
            border: 0;
            background-color: transparent;
            width: 46px;
            input {
              width: auto;
            }
            .MuiInputAdornment-root {
              margin: 0;
              .upload-icon {
                justify-content: center;
                button {
                  padding: 0;
                  right: 12px !important;
                  bottom: 12px;
                }
              }
            }
            &.Mui-disabled {
              display: none;
            }
          }
          label {
            position: relative;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: $gray-400;
            letter-spacing: 0;
            transform: unset;
          }
        }
      }
      .uploaded-image .close-btn {
        position: absolute;
        top: 6px;
        right: 80px;
        font-size: 18px;
        background: $white;
        padding: 4px;
      }
    }
    .custom-col {
      @media screen and (max-width: 575px) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

.close-btn-img {
  position: absolute;
  top: -12px;
  right: -10px;
  width: auto;
  height: auto;
  padding: 3px;
  margin-top: 0;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0.7;
  .anticon-close-circle {
    font-size: 14px;
  }
}
