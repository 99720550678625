.app-title {
    &_section {
        padding: 40px 0;
        @media screen and (max-width: 991px) {
            padding: 30px 0;
        }
        @media screen and (max-width: 767px) {
            padding: 20px 8px;
        }
    }
    &_wrapper {
        column-gap: 16px;
        @media screen and (max-width: 991px) {
            row-gap: 16px;
        }
        @media screen and (max-width: 767px) {
            column-gap: 12px;
            row-gap: 12px;
        }
    }
    &_left {
        h2 {
            font-size: 32px;
            line-height: 38px;
            font-weight: 600;
            margin-bottom: 4px;
            color: $dark-purple;
            @media screen and (max-width: 991px) {
                font-size: 28px;
                line-height: 32px;
            }
            @media screen and (max-width: 767px) {
                font-size: 24px;
                line-height: 28px;
            }
        }
        p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: $dark-purple-300;
            @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    &_center {
        @media screen and (max-width: 767px) {
            order: 1;
            width: 100%;
        }
    }
}