html, body {
  overflow-x: hidden;
  width: 100%;
}
html {
  font-family: "elza", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 0 !important;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.letter-space-1 {
  letter-spacing: 1px;
}

.main-wrapper {
  position: relative;
  z-index: 0;
  // overflow: hidden;
  &::before {
    content: "";
    display: block;
    width: 592px;
    height: 487px;
    background: url(../../images/vector-1.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -114px;
    left: -28px;
    z-index: -1;
    @media screen and (max-width: 1399px) {
      width: 480px;
      top: -90px;
    }
    @media screen and (max-width: 991px) {
      width: 350px;
      height: 350px;
      left: -16px;
      top: -70px;
    }
    @media screen and (max-width: 575px) {
      width: 220px;
      height: 220px;
      left: -8px;
      top: -50px;
    }
  }
  button,
  a {
    min-width: unset;
    margin-left: unset;
    text-decoration: unset;
    .MuiTouchRipple-root {
      display: none;
    }
  }
  a:hover {
    background-color: transparent;
  }
  .custom-container {
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    @media screen and (min-width: 1200px) {
      max-width: 1140px;
    }
    @media screen and (min-width: 1400px) {
      max-width: 1320px;
    }
    @media screen and (min-width: 1540px) {
      max-width: 1464px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .custom-row {
    margin-left: -12px;
    margin-right: -12px;
    width: unset;
    @media screen and (max-width: 767px) {
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  .custom-col {
    padding-left: 12px;
    padding-right: 12px;
    // width: 100%;
    @media screen and (max-width: 767px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  // .section {
  //   @media screen and (max-width: 767px) {
  //     padding-left: 8px !important;
  //     padding-right: 8px !important;
  //   }
  // }
}

.uploaded-img {
  width: 56px;
  height: 56px;
  min-height: 56px;
  object-fit: cover;
}

.contact-info-row {
  row-gap: 24px;
  .contact-info-block {
    position: relative;
    padding: 16px 24px;
    border: 1px solid $gray-100;
    border-radius: 12px;
    .contact-info-header {
      column-gap: 16px;
      i {
        font-size: 18px;
        line-height: 18px;
      }
    }
    .contact-info-detail {
      row-gap: 12px;
      h6 {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 0;
        font-size: 14px;
        line-height: 15px;
        font-weight: 500;
        color: $dark-purple-400;
        min-height: unset;
        i {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }
}

.no-resultfound {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 240px;
  }
}

.MuiTooltip-tooltip {
  padding: 8.5px 12px !important;
  background-color: $dark-purple-600 !important;
  font-size: 12px !important;
  line-height: 17px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  .MuiTooltip-arrow {
    color: $dark-purple-600;
  }
}

.dashboard-wrapper {
  margin: 60px auto 100px;
  .event-list-row {
    .custom-col {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    margin: 16px auto 40px;
    padding: 0 8px;
  }
  .app-title_wrapper {
    margin-bottom: 40px;
  }

  .dashboard-card {
    padding: 24px;
    background-color: $white;
    border: 1px solid $dark-purple-100;
    box-shadow: 0px 0px 32px -1px rgba(66, 59, 71, 0.15);
    transition: all ease-in-out 0.5s;
    position: relative;
    z-index: 0;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    display: flex;
    align-items: flex-start;
    // justify-content: center;
    flex-direction: column;
    min-height: 100%;

    .dashboard-head {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 15px 0;
      width: 100%;
      column-gap: 40px;
      row-gap: 16px;
      @media (max-width: 767.98px) {
        flex-wrap: wrap;
      }
      .dashboard-head-lt {
        display: block;
        text-align: left;
      }
      h2 {
        font-size: 32px;
        font-weight: 600;
        color: $dark-purple-500;
        margin: 0;
      }
      h5 {
        font-size: 16px;
        font-weight: 500;
        color: $dark-purple-500;
        margin: 0;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: $dark-purple-300;
        margin: 0;
      }
      .dashboard-head-rt {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: 767.98px) {
          flex: 0 0 100%;
          width: 100%;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
        .card-action {
          column-gap: 16px;
          row-gap: 12px;
          @media (max-width: 575px) {
            width: 100%;
          }
        }
        .btn {
          min-width: 112px;
          @media (max-width: 575px) {
            width: calc(50% - 8px);
          }
          @media (max-width: 375px) {
            padding: 14px 10px;
            min-width: unset;
          }
        }
        a {
          margin: 0;
          padding: 8px 11px;
          color: $purple-2;
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          @media (max-width: 767px) {
            margin-bottom: 15px;
          }
        }
        //         select{
        //           border: 1px solid $dark-purple-400;
        //           border-radius: 8px;
        //           -webkit-border-radius: 8px;
        //           -moz-border-radius: 8px;
        //           -ms-border-radius: 8px;
        //           -o-border-radius: 8px;
        //           height: 38px;
        //           min-width: 180px;
        //           font-size: 16px;
        //           font-weight: 600;
        //           color: $dark-purple-400;
        //           padding: 0 8px;
        // }
        .MuiInputBase-formControl {
          border: 1px solid $dark-purple-400;
          height: 38px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          min-width: 180px;
          width: auto;
          font-size: 16px;
          font-weight: 600;
          @media (max-width: 575px) {
            min-width: 100%;
          }
          &::placeholder {
            display: block;
            opacity: 1 !important;
            color: $dark-purple-400;
            -webkit-text-fill-color: $dark-purple-400;
          }
          &:hover {
            border: 1px solid $dark-purple-400;
          }
          .MuiAutocomplete-input {
            padding-top: 0px;
            padding-bottom: 0px;
            font-size: 16px;
            line-height: 23px;
            font-weight: 500;
            color: $dark-purple-400;
            text-transform: capitalize;
          }
          fieldset {
            border: 0px;
          }
        }
        .autoInput_textwrapper {
          .MuiFormControl-root {
            margin: 0;
            @media (max-width: 575px) {
              width: 100%;
            }
          }
          .MuiFormLabel-root {
            font-size: 16px;
            line-height: 0.7em;
            overflow: unset;
            transform: translate(14px, 16px) scale(1) !important;
            color: $dark-purple-400 !important;
            &.MuiFormLabel-filled {
              display: none;
            }
          }
          svg {
            color: $dark-purple-400;
          }
          .MuiInputBase-input {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: $dark-purple-400;
            text-align: left;
            height: 38px;
            padding: 10px 12px;
          }
        }
      }
    }
    .dashboard-body {
      width: 100%;
      text-align: center;
      .custom-table-container {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.dashboard-filer-group {
  column-gap: 16px;
  .MuiFormLabel-root {
    background-color: $white;
    padding: 0px 8px;
  }
}

.category-pills {
  .form-group {
    margin-bottom: 0;
    .form-control {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      input {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-purple-400;
        padding: 5px 14px;
        font-size: 16px;
        font-weight: 500;
        &::placeholder {
          color: $dark-purple-400;
        }
      }
      i {
        color: $dark-purple-400;
      }
    }
  }
}

.employee-details-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .employee-details {
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    padding: 30px 24px 24px;
    position: relative;
    line-height: 1.2;
    width: 100%;
    overflow: hidden;
    &::before {
      position: absolute;
      content: url(../../images/vector-8.svg);
      right: -10px;
      bottom: -25px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: $dark-purple-600;
      margin: 0 0 14px;
    }
    h2 {
      font-size: 46px;
      font-weight: 600;
      color: $dark-purple-600;
      margin: 0 0 12px;
    }
    h3 {
      font-size: 32px;
      font-weight: 600;
      color: $dark-purple-600;
      margin: 0;
    }
    &.bg-purple {
      background: rgb(226, 228, 254);
      background: linear-gradient(281deg, #aaaefd 2.69%, #e2e4fe 96.67%);
    }
    &.bg-peach {
      background: rgb(226, 228, 254);
      background: linear-gradient(292deg, #fb999b 3.68%, #fee2dd 96.26%);
    }
    &.bg-pink {
      background: rgb(226, 228, 254);
      background: linear-gradient(293deg, #e990b7 4.15%, #fbdbe3 100%);
    }
    .active-inactive-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .active-inactive-block {
        flex: 0 0 45%;
        width: 45%;
        text-align: center;
        position: relative;
      }
      span.line {
        background-color: #e3e5ff;
        width: 1px;
        height: 32px;
        // margin: 30px 5% 0;
      }
    }
  }
  .employee-details-col {
    width: 100%;
  }
  .employee-details-col-2 {
    width: 47.5%;
    margin-top: 20px;
    @media (max-width: 575.98px) {
      width: 100%;
      margin-top: 15px;
    }
    .employee-details {
      min-height: 155px;
      &::before {
        position: absolute;
        content: url(../../images/vector-9.svg);
        right: -10px;
        bottom: -25px;
      }
    }
  }
}

.MuiPickersPopper-root {
  .MuiButtonBase-root {
    &.Mui-selected {
      background-color: $purple-2;
      &:hover,
      &:focus {
        background-color: $purple-2;
      }
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.wizard_section {
  position: relative;
  padding: 0 0 100px;
  @media screen and (max-width: 1399px) {
    padding: 0 0 80px;
  }
  @media screen and (max-width: 991px) {
    padding: 0 0 56px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 8px 40px;
  }
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin fadeInUp($delay) {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: $delay;
}

.fade-in {
  @include fadeInUp(0.3s);
}

@include keyframes(fadeInUp) {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mouse-scroll-next-section {
  position: fixed;
  bottom: 125px;
  left: auto;
  right: calc(33% - 510px);
  width: max-content;
  z-index: 9;
  @media screen and (max-width: 1599px) {
    right: calc(30% - 400px);
  }
  @media screen and (max-width: 1399px) {
    right: 60px;
  }
  @media screen and (max-width: 991px) {
    right: 16px;
  }
  a {
    display: flex;
  }
  svg {
    circle {
      opacity: 0;
      &:nth-child(2) {
        animation: firstDot 2s ease-in-out infinite;
      }
      &:nth-child(3) {
        animation: secondDot 2s ease-in-out infinite;
      }
      &:nth-child(4) {
        animation: thirdDot 2s ease-in-out infinite;
      }
    }
    path {
      &:nth-child(6) {
        stroke-dasharray: 15;
        stroke-dashoffset: 15;
        animation: arrow-animation 2s ease-in-out infinite;
      }
      &:nth-child(5) {
        opacity: 0;
        animation: arrowTip-animation 2s ease-in-out infinite;
      }
    }
  }
}

@include keyframes(firstDot) {
  0%,
  100% {
    opacity: 0;
  }
  17%,
  34%,
  51%,
  68%,
  85% {
    opacity: 1;
  }
}
@include keyframes(secondDot) {
  0%,
  17%,
  100% {
    opacity: 0;
  }
  34%,
  51%,
  68%,
  85% {
    opacity: 1;
  }
}
@include keyframes(thirdDot) {
  0%,
  17%,
  34%,
  100% {
    opacity: 0;
  }
  51%,
  68%,
  85% {
    opacity: 1;
  }
}
@include keyframes(arrow-animation) {
  0%,
  17%,
  34%,
  51%,
  100% {
    stroke-dashoffset: 15;
    opacity: 0;
  }
  68%,
  90% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}
@include keyframes(arrowTip-animation) {
  0%,
  17%,
  34%,
  51%,
  68% {
    opacity: 0;
  }
  85%,
  100% {
    opacity: 1;
  }
}

.recommended-events_list {
  gap: 56px;
  @media screen and (max-width: 1539px) {
    gap: 48px;
  }
  @media screen and (max-width: 1399px) {
    gap: 40px;
  }
  @media screen and (max-width: 1199px) {
    gap: 30px;
  }
  @media screen and (max-width: 767px) {
    gap: 20px;
  }
  .custom-card.event-card.recommended-events_item {
    padding: 32px;
    outline: unset;
    @media screen and (max-width: 1399px) {
      padding: 20px;
    }
    @media screen and (max-width: 575px) {
      padding: 16px;
    }
    &:hover {
      box-shadow: 0px 0px 32px -1px transparentize($dark-purple-500, 0.85);
    }
    &::before {
      background-image: url(../../images/vector-11.svg);
      width: 186px;
      height: 190px;
      top: unset;
      bottom: 0;
      left: auto;
      @media screen and (max-width: 1399px) {
        width: 150px;
        height: 153px;
      }
      @media screen and (max-width: 575px) {
        width: 110px;
        height: 112px;
      }
    }
  }
}

.box_image {
  background-image: url(../../images/FHC.png);
}

.event_section,
.event-list_section {
  @media screen and (max-width: 767px) {
    padding: 0 8px;
  }
}

.notification-tooltip {
  &[data-popper-placement*="bottom"] {
    .MuiTooltip-tooltip {
      margin-top: 24px !important;
      margin-left: -40px;
    }
  }
  &[data-popper-placement*="bottom-end"] {
    .MuiTooltip-tooltip {
      margin-left: 0;
      margin-right: -40px;
      .MuiTooltip-arrow {
        margin-left: -22px;
      }
    }
  }
  &[data-popper-placement*="bottom-start"] {
    .MuiTooltip-tooltip {
      @media screen and (max-width: 575px) {
        margin: 0 auto;
      }
      .MuiTooltip-arrow {
        @media screen and (max-width: 575px) {
          margin-left: unset;
        }
      }
    }
  }
  .MuiTooltip-tooltip {
    background-color: #fff !important;
    box-shadow: 0 0 32px 0 transparentize($gray-450, 0.88);
    border-radius: 24px;
    padding: 24px !important;
    max-width: 400px;
    @media screen and (max-width: 1199px) {
      max-width: 340px;
    }
    @media screen and (max-width: 575px) {
      max-width: 90%;
    }
    .notification-tooltip-wrapper {
      column-gap: 16px;
      p {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        color: $gray-600;
      }
    }
    .MuiTooltip-arrow {
      color: #fff;
      width: 26px;
      height: 17px;
      margin-top: -16px !important;
      margin-left: 22px;
      box-shadow: -32px 0 32px rgba(35, 31, 32, 0.12);
    }
  }
}

.chatbtn {
  background: $linear-background;
  position: fixed !important;
  bottom: 7rem;
  right: 1.5rem;
  z-index: 9999;
  width: 7.375rem;
  height: 3.125rem;
  border-radius: 2.5rem !important;
  color: white;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
  align-items: center;
  box-shadow: $box-shadow;
  text-decoration: none;
  @media screen and (min-width:$sm-breakpoint) and (max-width:$md-breakpoint) {
    border-radius: 50%;
    bottom: 14rem;
    right: 1rem;
  }

  @media screen and (max-width: $sm-breakpoint) {
    width: 4.125rem;
    height: 4.125rem;
    border-radius: 50%;
    bottom: 1rem;
    right: 1rem;
  }

  .chat-icon {
    color: $white;
    width: 1.2rem;
    height: auto;
    @media screen and (max-width: $sm-breakpoint) {
      width: 1.8rem;
    }
  }
  .chat-content {
    color: $white;
    font-weight: bold;
    @media screen and (max-width: $sm-breakpoint) {
      display: none;
    }
  }
}
