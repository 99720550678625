#scoreDialog {
  &.custom-dialog {
    .MuiPaper-root {
      padding: 0 !important;
      max-width: 800px;
      min-width: 800px;
      @media screen and (max-width: 992px) {
        max-width: 600px;
        min-width: 600px;
      }
      @media screen and (max-width: 768px) {
        max-width: 500px;
        min-width: 500px;
      }
      @media screen and (max-width: 575px) {
        max-width: 450px;
        min-width: 450px;
      }
      @media screen and (max-width: 480px) {
        max-width: 100%;
        min-width: auto;
      }
      .custom-dialog-title {
        padding: 10px -2px !important;
        background-color: $dark-purple;
      }
      &.MuiAccordion-root {
        min-width: auto !important;
        background-color: #E7EAEC;
        border-radius: 10px;
        &::before {
          opacity: 0 !important;
        }
        .accordion-header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }
        .score-border {
          border-bottom: 1.5px dotted #bcb7b7;
          width: 170px;
          margin: 10px;
          @media screen and (max-width: 992px) {
            width: 60px;
          }
        }
        .accordion-btn {
          .MuiButton-startIcon {
            margin-right: 1px !important;
          }
        }
        .status-icon {
          height: 18px;
          width: 18px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
        }
      }
    }
  }
}
