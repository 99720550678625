$white: #fff;
$black: #000;
$gray: #efe5e5;
$gray-50: #faf4f4;
$gray-100: #D5D7D8;
$gray-150: #ACAFB1;
$gray-200: #131618;
$gray-300: #82868A;
$gray-400: #585E63;
$gray-450: #231F20;
$gray-500: #201F1E;
$gray-600: #2F363D;
$gray-700: #03223c;
$gray-800: #2f363c;
$gray-900: #383838;

$disabled-1: #F6F5F7;
$disabled-2: #F9F6F6;


// Primary color is the main color of the application which is used
// for navbar, active links and primary button.

// ------ Primary Colors from google material design color palette ------
$dark-purple: #18112F;
$dark-purple-100: #D1CFD5;
$dark-purple-300: #747082;
$dark-purple-400: #464159;
$dark-purple-500: #423b47;
$dark-purple-600: #252B30;
$dark-purple-700: #130E26;
$dark-purple-800: #220C46;
$dark-purple-900: #0E0A1C;

$purple:#c06df3;
$purple-100: #B445B5;
$purple-200: #9A57C2;
$purple-300: #AF18BE;
$purple-400: #9435E5;
$light-purple: #F8D5FD;
$special-purple:#9435E5;

$purple-1: #391474;
$purple-1-100: #151877;

$purple-2: #7236ae;
$purple-2-100: #E7E8FF;
$purple-2-200: #AAAEFD;
$purple-2-300: #832173;

$purple-3: #e5a8fb;
$purple-3-500: #EE55AA;

$purple-4: #fae2fe;
$purple-4-100: #FEF9FF;
$purple-4-200: #FFEFF6;
$purple-5-100: #EBE7FF;

$primary-blue-200: #9cbbd5;
$primary-blue-400: #3976ac;
$primary-blue-500: #075497;
$primary-blue-700: #04325B;

$blue: #7177f8;

$green: #33AC96;
$green-100: #43AC89;
$green-200: #37A07D;
$mediumseagreen: #20b57f;
$red: #e32d2d;
$red-100: #CF2832;
$sky-blue: #366fd2;
$orange: #d66916;
$chart-color-100: #FB999B;
$chart-color-200: #F35577;
$chart-color-300: #B7438D;
$chart-color-400: #7177F9;

$coral-100: #FEE2DD;
$coral-200: #FB999B;
$coral-300: #F35577;
$coral-400: #AE2A60;
$coral-500: #74104C;

// break points
$xs-breakpoint: 300px;
$sx-breakpoint: 372px;
$xsm-breakpoint: 490px;
$sm-breakpoint: 600px;
$md-breakpoint: 900px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1600px;
$xxl-breakpoint: 2180px;

$grid-breakpoints: (
  xsBreakpoint: $xs-breakpoint,
  smBreakpoint: $sm-breakpoint,
  mdBreakpoint: $md-breakpoint,
  lgBreakpoint: $lg-breakpoint,
  xlBreakpoint: $xl-breakpoint,
);

// custom headers
$header-height: 0.875rem;
$home-header-height: 22rem;
$home-header-height-xs: 18rem;
$header-height-xs: 2.25rem;
$shared-header-height: calc(2.9375rem + 43px);
$shared-header-height-xs: calc($header-height-xs + 20px);
$header-height-logo: 7.25rem;
$headings-line-height: 1.5 !default;

// Text Classes
$primary: #391474;
$purple-v2: #7236ae;
$lighter-purple-v2: #C06DF3;
$lightest-purple-v2: #efe5ef;
$success: #46962b;
$linear-background: #391474;
$border-gray: #E5E5E5;
$warn-red: #BE1212;
$warn-border: #B71111;
$medium-gray: #E3E3E3;
$moderate-gray: #f9f9f9;
$shed-grey: #8DA1AE;
$text-dark-black: #313131;
$light-gray: #8b8b8b;
$special-gray:#545454;
$special-purple:#9435E5;
$text-black: #3a3a3a;
$very-light-gray: #e0e0e0;

//font family
$font-family-sans-serif: "Raleway", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

//font size
$font-size-base: 1rem !default;
$font-size-lg: ($font-size-base * 1.125) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-size-md: ($font-size-base * 1.05) !default;
$font-size-xsm: ($font-size-base * 0.575) !default;

//font weight
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-800: 800 !default;
$font-weight-base: $font-weight-normal !default;
$h3-font-size: $font-size-base * 1.5 !default;

// box-shadow
$box-shadow: 0rem 0.1875rem 0.375rem #00000029;
