.custom-table {
  @media screen and (max-width: 1099px) {
    width: 1100px;
  }
  &-responsive {
    width: 100%;
    overflow-x: auto;
    padding-top: 12px;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $disabled-1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dark-purple-500;
    }
  }
  &-container {
    margin-left: -40px;
    margin-right: -40px;
    @media screen and (max-width: 1540px) {
      margin-left: -30px;
      margin-right: -30px;
    }
    @media screen and (max-width: 1399px) {
      margin-left: -20px;
      margin-right: -20px;
    }
    @media screen and (max-width: 1199px) {
      margin-left: -30px;
      margin-right: -30px;
    }
    @media screen and (max-width: 767px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  &-filter {
    padding: 0 40px 12px;
    justify-content: end;
    @media screen and (max-width: 1540px) {
      padding: 0 30px 8px;
    }
    @media screen and (max-width: 1399px) {
      padding: 0 20px 4px;
    }
    @media screen and (max-width: 1199px) {
      padding: 0 30px 8px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px 4px;
    }
    .role-header {
      margin-left: 0;
      div {
        margin-right: 0;
      }
    }
  }
  &-search {
    border: 0px !important;
    position: relative;
    i {
      font-size: 16px;
      line-height: 16px;
      color: $dark-purple-400;
      position: absolute;
      right: 16px;
      top: 12px;
      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 14px;
        top: 11px;
      }
    }
    input {
      border-radius: 8px;
      border: 1px solid $dark-purple-300;
      background: $white;
      padding: 12px 16px;
      padding-right: 32px;
      min-width: 240px;
      height: 41px;
      font-size: 12px;
      line-height: 17px;
      font-weight: 400;
      color: $dark-purple-400;
      @media screen and (max-width: 991px) {
        height: 39px;
      }
    }
    fieldset {
      border: 0px;
    }
  }
  &-action {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  thead {
    tr {
      box-shadow: 0 0 12px transparentize($dark-purple-100, 0.65);
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: $disabled-1;
      }
    }
  }
  th,
  td {
    height: 45px;
    padding: 12px;
    font-size: 16px;
    line-height: 18px;
    vertical-align: middle;
    border: 0;
    @media screen and (max-width: 991px) {
      font-size: 14px;
      line-height: 16px;
      height: 42px;
      padding: 10px;
    }
    &:nth-child(1) {
      padding-left: 40px;
      @media screen and (max-width: 1540px) {
        padding-left: 30px;
      }
      @media screen and (max-width: 1399px) {
        padding-left: 20px;
      }
      @media screen and (max-width: 1199px) {
        padding-left: 30px;
      }
      @media screen and (max-width: 767px) {
        padding-left: 20px;
      }
    }
    &:last-child {
      padding-right: 40px;
      @media screen and (max-width: 1540px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 1399px) {
        padding-right: 20px;
      }
      @media screen and (max-width: 1199px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 767px) {
        padding-right: 20px;
      }
    }
  }
  th {
    color: $purple-1;
    font-weight: 500;
  }
  td {
    color: $gray-500;
    font-weight: 400;
  }
  &-footer {
    background-color: transparent;
    padding: 24px 40px 0px;
    @media screen and (max-width: 1540px) {
      padding: 20px 30px 0px;
    }
    @media screen and (max-width: 1399px) {
      padding: 16px 20px 0px;
    }
    @media screen and (max-width: 1199px) {
      padding: 20px 30px 0px;
    }
    @media screen and (max-width: 767px) {
      padding: 16px 20px 0px;
    }
    &-container {
      column-gap: 32px;
      row-gap: 12px;
    }
  }
  &-rows {
    .footer-text {
      font-size: 16px;
      line-height: 18px;
      color: $purple-1;
      font-weight: 500;
      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .form-control {
      .MuiSelect-select,
      input {
        border-radius: 8px;
        border: 0px;
        background-color: $disabled-1;
        padding: 7px 12px;
        padding-right: 32px;
        height: 32px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $dark-purple-500;
        @media screen and (max-width: 991px) {
          font-size: 12px;
          line-height: 16px;
          height: 30px;
          padding: 6px 10px;
          padding-right: 30px;
        }
      }
      input {
        min-width: 60px;
        padding: 7px 12px;
      }
      fieldset {
        border: 0px;
      }
    }
  }
  &-pagination {
    ul {
      column-gap: 8px;
      li {
        button {
          margin: 0;
          border-radius: 8px;
          border: 0px;
          background-color: $disabled-1;
          padding: 7px 12px;
          width: 32px;
          height: 32px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: $dark-purple-500;
          @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 16px;
            height: 30px;
            width: 30px;
            padding: 6px 10px;
          }
          &:hover {
            background-color: $purple-2;
            color: $white;
          }
        }
      }
    }
    &-rows {
        .footer-text {
            font-size: 14px;
            line-height: 16px;
            color: $purple-1;
            font-weight: 400;
            @media screen and (max-width: 991px) {
                font-size: 12px;
                line-height: 14px;
            }
        }
        .form-control {
            .MuiSelect-select, input {
                border-radius: 8px;
                border: 0px;
                background-color: $disabled-1;
                padding: 7px 12px;
                padding-right: 32px;
                height: 32px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: $dark-purple-500;
                @media screen and (max-width: 991px) {
                    font-size: 10px;
                    line-height: 14px;
                    height: 30px;
                    padding: 6px 10px;
                    padding-right: 30px;
                }
            }
            input {
                min-width: 60px;
                padding: 7px 12px;
            }
            fieldset {
                border: 0px;
            }
        }
    }
    &-pagination {
        ul {
            column-gap: 8px;
            li {
                button {
                    margin: 0;
                    border-radius: 8px;
                    border: 0px;
                    background-color: $disabled-1;
                    padding: 7px 12px;
                    width: 32px;
                    height: 32px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: $dark-purple-500;
                    @media screen and (max-width: 991px) {
                        font-size: 12px;
                        line-height: 16px;
                        height: 30px;
                        width: 30px;
                        padding: 6px 10px;
                    }
                    &:hover {
                        background-color: $purple-2;
                        color: $white;
                    }
                }
            }
        }
    }
  }
  .admin-icon {
    padding-left: 5px;
    color: $purple-1;
    font-size: 12px;
  }
}
