// stepper

.stepper {
  padding: 1.8125rem 0rem 1.8125rem 0rem;
  width: 100%;

  .stepper-label {
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.7rem;
    }
  }

  .stepper-detail {
    color: $white;
    font-size: $font-size-base;
    cursor: pointer;
  }

  .MuiStepLabel-vertical {
    padding: 0 !important;
    width: 100%;
    justify-items: end;
  }

  .MuiStepLabel-labelContainer {
    order: 1;
    width: 80%;
    display: flex;
    justify-content: flex-end !important;
    text-align: end;
    padding-right: 1rem;

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
      justify-content: center !important;
      text-align: center;
      padding-right: 0rem;
    }
  }

  .MuiStepLabel-iconContainer {
    order: 2;
  }

  .MuiStepConnector-vertical {
    display: flex;
    justify-content: flex-end;
    padding-right: 4.09rem;
  }
}

.stepper-layout {
  margin-top: $shared-header-height;

  .application-form {
    .form-heading-container {
      height: 3.125rem;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      margin-bottom: 1.4375rem;

      .heading {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        margin-left: 0.75rem;
      }
    }

    .form-container {
      max-width: 31.25rem;
      width: 100%;
      min-height: 32.5rem;

      .textbox {
        margin-top: 1.125rem;
      }
    }
  }

  .stepper-grid {
    @media screen and (max-width: $md-breakpoint) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .stepper-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
  }
}

.application-page-container {
  min-height: 31.5rem;
  height: 100%;

  @media screen and (max-width: $sm-breakpoint) {
    min-height: 25.5rem;
  }
}

.stepper-field {
  max-width: 31.25rem !important;
  width: 100% !important;

  .MuiTextField-root > .MuiInputBase-formControl {
    padding: 10px 5px;
  }
}

.stepper-content {
  font-size: $font-size-base !important;
  color: $black;
  // line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

//sub heading
.sub-heading {
  color: $purple-v2 !important;
  font-size: $font-size-lg !important;
  font-weight: $font-weight-bold !important;
  // line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-base !important;
  }
}

//header
.pension-application-header {
  height: 3.125rem;
  background-color: $lightest-purple-v2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.75rem;
  font-size: $font-size-base !important;
  font-weight: $font-weight-bold;
  color: $black;
  margin-bottom: 1.4375rem;

  @media screen and (max-width: $sm-breakpoint) {
    flex-direction: column;
    min-height: 3.125rem;
    max-height: 3.5rem;
    height: 100%;
    font-size: $font-size-sm;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .choosenAmount {
    padding-right: 0.75rem;

    span {
      color: $success;
    }

    .modified-span {
      color: $purple-v2 !important;
    }
  }
}

.outlined-btn {
  background: $white;
  color: $linear-background !important;
}

.disable-backbtn {
  text-transform: capitalize !important;
  font-weight: $font-weight-bold !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm;
  }
}

.backbtn {
  color: #313131 !important;
  text-transform: capitalize !important;
  font-weight: $font-weight-bold !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm;
  }
}

//cancellation

.space-from-top {
  margin-top: calc($header-height);

  @media screen and (max-width: $sm-breakpoint) {
    margin-top: calc($header-height-xs);
  }
}

.light-backbtn {
  color: #141414 !important;
  text-transform: none !important;
  font-weight: $font-weight-bold !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm;
  }
}

.cancel-plan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;
  text-align: center;

  @media screen and (max-width: $xsm-breakpoint) {
    margin: 0 0.5rem !important;
    margin-top: -4rem !important;
  }
}

.cancel-plan-content-flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;

  .padding-content-heading {
    font-size: $font-size-lg;
    color: $purple-v2;
    padding: 0 1.875rem !important;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
      font-size: $font-size-base;
    }
  }

  .padding-content {
    padding: 0 1.875rem !important;
    color: $black;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
    }
  }

  @media screen and (max-width: $xsm-breakpoint) {
    margin: 0 0.5rem !important;
    margin-top: -4rem !important;
  }

}

.generic-heading-cotainer {
  width: 100%;
  height: 3.75rem;
  background: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 0.2rem;
  }

  .generic-heading {
    color: $white;
    font-size: 1rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.my-plan-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.125rem;
  width: 85.875;
  background: $primary;
  padding: 1rem;

  .my-plan-heading {
    color: $white;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 3rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem !important;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 9.125rem;
  }
}

// button

.SharedHeader {
  height: $shared-header-height;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @media screen and (max-width: $sm-breakpoint) {
    height: $shared-header-height-xs;
  }

  .SharedHeader-sub-heading {
    font-size: $font-size-base;
    color: $text-dark-black;
    font-weight: $font-weight-medium;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .SharedHeader-sideline {
    width: 5px;
    height: 2.5rem;
    background-color: $primary;
    border-radius: 1.25rem;

    @media screen and (max-width: $sm-breakpoint) {
      height: 2rem;
    }
  }

  .SharedHeader-heading {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
    color: $primary;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-lg;
    }
  }

  .organisation-logo-container {
    max-width: 10rem;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $sm-breakpoint) {
      max-width: 6rem;
    }

    .organisation-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .bottomspace-sharedheader {
    height: 30px;
    width: 100%;

    // background-color: $white;
    @media screen and (max-width: $sm-breakpoint) {
      height: 18px;
    }
  }
}

//avc pension topup

.ContributionCalculator {
  width: 34.9375rem;
  height: 6.25rem;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: $sm-breakpoint) {
    width: 100%;
  }

  .avc-pension-form-footer {
    background-color: rgba(35, 106, 163, 0.1);
    width: 31.25rem;
    height: 4.125rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    padding: 0 1.5rem;

    @media screen and (max-width: $sm-breakpoint) {
      width: 85%;
      height: 6rem;
      padding: 0 1rem;
    }

    .content {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.75rem;
        width: 85%;
      }
    }
  }

  .calculator-icon-container {
    position: absolute;
    cursor: pointer;
    background-color: $primary;
    width: 6.25rem;
    height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 0;
    right: 0;
  }

  .calculator-icon {
    color: $white;
  }
}

// checkbox
.checkbox-container {
  .form-control-label {
    align-items: start;
  }

  .checkbox {
    color: $primary !important;
    padding: 0;

    & > input {
      color: $primary !important;
    }

    &.Mui-checked > input {
      color: $primary !important;
    }
  }
}

.signature-container {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  border-radius: 0.375rem;
  height: 20.3125rem;
  padding: 1rem;

  .form-control {
    width: 100%;

    .signature {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .signature-heading {
        font-weight: $font-weight-bold;
      }

      .clear-btn {
        text-transform: none;
        color: $light-gray;
      }
    }
  }
}

//table

.pension-table {
  .pension-table-head {
    background-color: $primary;

    .pension-table-cell {
      color: $white;
      font-weight: $font-weight-bold;
      height: 3.125rem !important;
      text-align: left;
      padding: 10 !important;
      font-size: $font-size-base;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }
  }

  .pension-table-cell {
    border: 1px solid $light-gray;
    font-size: $font-size-base;
    height: 3.125rem !important;
    text-align: left;
    padding: 10 !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.custom-input .MuiInputBase-root .MuiOutlinedInput-input {
  padding: 15px 0px;
  height: 2rem;
}

.amend-custom-input .MuiInputBase-root .MuiOutlinedInput-input {
  height: 3.4rem !important;
}

//manage my plan

.manage-my-shared-cost * {
  font-family: "Inter", sans-serif !important;

  .currency-contribution {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .payroll-table {
    margin-left: 53px;
    @media screen and (max-width: $md-breakpoint) {
      margin-left: 10px;
    }
    @media screen and (max-width: $xsm-breakpoint) {
      margin-left: 0px;
    }
  }

  .currency {
    font-size: 22px;
    font-weight: 500;
    margin-left: 10px;
  }

  .amend-container {
    margin-left: 20px;
    & > button {
      padding: 16px;
      width: 56px;
      &:hover {
        background-color: #eee;
      }
      min-width: none;
    }
    @media screen and (max-width: $xsm-breakpoint) {
      margin-left: 0px;
    }
  }
}

.plan-detail-container {
  width: 100%;
  border: 1px solid #d8d4d3;
  background-color: $white;

  .banner-container {
    width: 100%;
    height: 11.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0.875rem;
    }

    .plan-with-provider {
      width: 15.937rem;
      height: 4.562rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: $md-breakpoint) {
        max-width: 80%;
        max-height: 80%;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 70%;
        max-height: 70%;
      }
    }

    .plan-with-provider-text {
      font-size: 18px;
      color: #000;
      font-weight: 400;
      text-align: center;
      line-height: 1.36rem;

      @media screen and (max-width: $md-breakpoint) {
        font-size: 1rem;
        line-height: 1.19rem;
      }

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    .status-heading {
      font-size: 1.125rem;
      color: $white;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        margin-top: 0.8rem;
      }
    }

    .status-name {
      font-size: 1.125rem;
      color: $white;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        margin-top: 0.8rem;
      }
    }
  }

  .info-container {
    background-color: $white;

    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
      padding: 0.875rem;
    }

    .align-left {
      @media screen and (max-width: $md-breakpoint) {
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }
    }

    .plan {
      color: $special-gray !important;
      font-family: "Inter", sans-serif !important;
      font-size: 1rem !important;
      font-weight: $font-weight-medium !important;
      line-height: 1.3125rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-size: $font-size-sm !important;
        text-align: start;
      }

      @media screen and (max-width: $sx-breakpoint) {
        font-size: $font-size-xsm !important;
      }
    }

    .plan .value {
      font-size: 22px !important;
      line-height: 1.664rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-weight: $font-weight-bold !important;
        font-size: 1.175rem !important;
        line-height: 1.464rem !important;
      }

      @media screen and (max-width: $sx-breakpoint) {
        font-weight: $font-weight-bold !important;
        font-size: $font-family-base !important;
        line-height: 1rem !important;
      }
    }

    .icon-btn {
      font-weight: 300 !important;
      color: $purple-v2 !important;
      line-height: 1.212rem;
      font-family: "Inter", sans-serif !important;
      width: 19rem !important;
      border: 2px solid $purple-v2 !important;
      text-transform: none !important;
      border-radius: 0.5rem !important;

      @media screen and (max-width: $md-breakpoint) {
        max-width: 16rem;
        font-size: 0.7rem !important;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 10rem;
        font-size: 0.5rem !important;
      }
    }
  }
}

.text-bold {
  font-size: $font-size-lg !important;
  font-weight: 700 !important;
  line-height: 1.36rem !important;
  font-family: "Inter", sans-serif !important;

  @media screen and (max-width: $xsm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

.text-bold-lg {
  font-weight: 900 !important;
  font-size: 1.375rem !important;
  font-family: "Inter", sans-serif !important;

  @media screen and (max-width: $xsm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

.downlaod-application {
  flex-direction: row !important;
  & > button {
    padding: 16px;
    width: 56px;
    min-width: none !important;
    &:hover {
      background-color: #eee !important;
    }
  }

  & > .home-typography {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    font-family: "Elza", "Inter", sans-serif !important;
    &:hover {
      color: $purple-v2;
    }
  }
}

.service-container {
  padding: 0 1rem;

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    display: flex;
    justify-content: center;
  }

  .service-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .service-icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .service-content {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      color: $purple-v2;
      text-align: center;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }
  }
}

.plan-guidance-container {
  background-color: $white;
  border: 1px solid #d8d4d3;
  padding: 1.875rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.875rem;
  }

  .plan-guidance-heading {
    font-size: 1.375rem;
    color: $purple-v2;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }

  .plan-guidance-content {
    color: $black;
    font-size: $font-size-base;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.organisation-large-logo {
  height: 10.5rem;
  width: 100%;
  margin-top: 8rem;

  .organisation-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.no-plan-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .strong-heading {
    color: $purple-v2;
  }

  .bottom-header {
    background-color: $light-purple;
    width: 38rem;
    padding: 0rem 3.5rem 1.5rem 3.5rem;
    border-radius: 0.5rem;

    @media screen and (max-width: $sm-breakpoint) {
      width: 18rem;
      padding: 0rem 1rem 1.75rem 1rem;
    }

    @media screen and (max-width: $xs-breakpoint) {
      width: 14rem;
      padding: 0rem 0.5rem 1.5rem 0.5rem;
    }
  }
}

.width-restriction {
  min-width: fit-content !important;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 1rem !important;
    margin-top: -4rem !important;
  }
}

.avc-amount-box {
  width: 100%;
  border: 1px solid #d8d4d3;
  min-height: 10rem;
  padding: 1rem;
}

.long-header {
  height: 3.75rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    min-height: 6rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    min-height: 8rem !important;
  }
}

.amend-form-tab {
  background-color: $light-purple !important;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  height: auto;
  width: 30%;

  @media screen and (max-width: $md-breakpoint) {
    height: auto;
  }

  @media screen and (max-width: $sm-breakpoint) {
    width: 100%;
  }

  .form-text {
    font-size: 14px;
  }
}

.accordion {
  .accordion-summary {
    border: 1px solid $light-gray;
    flex-direction: row-reverse;
    max-height: 3.4375rem;
    height: 100%;
    gap: 1rem;
    padding: 1rem !important;
    .MuiAccordionSummary-expandIconWrapper {
      font-size: $font-size-lg !important;
      color: $text-black;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm !important;
      }
      &.Mui-expanded {
        transform: rotate(90deg);
      }
    }
    .accordion-summary-text {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm !important;
      }
    }

    @media screen and (max-width: $xsm-breakpoint) {
      max-height: 4.4375rem;
    }
  }
  .accordion-details {
    border: 1px solid $very-light-gray;
    .fund-link {
      color: $primary;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
    .MuiTypography-body1.checkbox-label {
      margin-bottom: 0;
    }
  }
}

.amend-page-accordian {
  .summary {
    border: none !important;
    background-color: $light-purple !important;

    .MuiAccordionSummary-expandIconWrapper {
      color: $purple-v2 !important;
    }
  }

  .summary-text {
    font-weight: bold !important;
    color: $purple-v2 !important;
  }
}

.amendment-reason-box {
  padding: 1.3rem;
}

//customer service
.customer-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-service-content {
    font-size: $font-size-base;
    color: $purple-v2;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .customer-service-button {
    width: 10rem;
    height: 3.125rem;
    background-color: $primary;
    color: $white;
    border-radius: 0.5rem;
    text-transform: capitalize !important;

    &:hover {
      background-color: $primary;
    }
  }
}

// Pension TopUp Header
.pension-top-header {
  &__row {
    p {
      font-family: "Inter", sans-serif !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      color: #18112f80;
    }
  }
}
