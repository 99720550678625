// Componant: Footer

.footer {
    background-color: $dark-purple;
    padding: 60px 0 42px;
    @media screen and (max-width: 1399px) {
        padding: 48px 0 28px;
    }
    @media screen and (max-width: 991px) {
        padding: 36px 0 20px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 8px 20px;
    }
    .footer-content {
        .footer-top {
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid $dark-purple-400;
            column-gap: 16px;
            @media screen and (max-width: 1399px) {
                padding-bottom: 24px;
                margin-bottom: 24px;
            }
            @media screen and (max-width: 991px) {
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
            .footer-logo {
                img {
                    @media screen and (max-width: 1399px) {
                        width: 90px;
                    }
                    @media screen and (max-width: 991px) {
                        width: 75px;
                    }
                }
            }
            .footer-info {
                @media screen and (max-width: 767px) {
                    margin-top: 16px;
                }
                h3 {
                    font-size: 32px;
                    line-height: 38px;
                    font-weight: 700;
                    color: $white;
                    margin-bottom: 16px;
                    @media screen and (max-width: 1399px) {
                        font-size: 28px;
                        line-height: 32px;
                        margin-bottom: 12px;
                    }
                    @media screen and (max-width: 991px) {
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: 8px;
                    }
                    @media screen and (max-width: 767px) {
                        margin-bottom: 16px;
                    }
                    @media screen and (max-width: 575px) {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    span {
                        color: $purple;
                    }
                }
                ul {
                    column-gap: 16px;
                    @media screen and (max-width: 767px) {
                        row-gap: 8px;
                    }
                    li {
                        a {
                            font-size: 14px;
                            line-height: 17px;
                            font-weight: 400;
                            color: $dark-purple-100;
                            position: relative;
                            z-index: 2;
                            @media screen and (max-width: 1399px) {
                                font-size: 12px;
                                line-height: 15px;
                            }
                        }
                    }
                }
            }
        }
        ul {
            width: max-content;
            margin-left: auto;
            @media screen and (max-width: 767px) {
                margin-left: unset;
            }
            li {
                width: unset;
                a {
                    padding: 0;
                }
            }
        }
        .footer-bootom {
            column-gap: 16px;
            p {
                font-size: 12px;
                line-height: 15px;
                font-weight: 400;
                color: $gray-100;
                @media screen and (max-width: 767px) {
                    text-align: center;
                }
            }
            .social-list {
                column-gap: 8px;
                @media screen and (max-width: 767px) {
                    margin: 16px auto 0;
                }
                li {
                    a {
                        width: 32px;
                        height: 32px;
                        border-radius: 32px;
                        background-color: transparent;
                        color: $purple;
                        font-size: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all ease-in-out .5s;
                        @media screen and (max-width: 1399px) {
                            width: 28px;
                            height: 28px;
                            font-size: 15px;
                        }
                        @media screen and (max-width: 991px) {
                            width: 24px;
                            height: 24px;
                            font-size: 12px;
                        }
                        &:hover {
                            background-color: $purple;
                            color: $purple-4-100;
                        }
                    }
                }
            }
        }
    }
}