.avc-view {
  .parent {
      position: absolute;
      top: 0;
      left: 271px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 4px;
      color: $green;
    }
    .frame-inner {
      position: relative;
      width: 226.6px;
      height: 186px;
      object-fit: cover;
    }
    .div2 {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 600;
    }
    .vector-group {
      position: relative;
      width: 74px;
      height: 29px;
    }
    .group {
      position: absolute;
      top: 0;
      left: 224px;
      height: 67px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      color: $green;
    }
    .frame-parent3 {
      position: relative;
      width: 341px;
      height: 67px;
      color: $primary-blue-700;
    }
    .organisation-members {
      margin: 0;
    }
    .frame-child16 {
      position: relative;
      width: 90px;
      height: 90px;
    }
    .group-wrapper4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .lorem-ipsum-dolor4,
    .taxation {
      align-self: stretch;
      position: relative;
    }
    .lorem-ipsum-dolor4 {
      font-size: 14px;
      line-height: 18px;
      font-family: 'Roboto', sans-serif;
    }
    .taxation-parent {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
    }
    .frame-parent21,
    .frame-parent22,
    .frame-parent23 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .frame-parent22 {
      border-radius: 32px;
      background-color: $white;
      border: 1px solid $dark-purple-100;
      width: 390px;
      align-items: center;
      padding: 32px;
      gap: 24px;
      box-shadow: 0 0 24px transparent;
      transition: all ease-in-out .5s;
      &:hover {
        box-shadow: 0 0 24px rgba(109, 142, 171, 0.22);
        border-color: $primary-blue-200;
      }
    }
    .frame-parent21 {
      justify-content: center;
      align-items: flex-start;
      gap: 32px;
      color: $gray-800;
      padding-top: 64px;
    }
    .mask-group-icon {
      position: relative;
      width: 1920px;
      height: 784px;
      z-index: 0;
    }
    .dreams {
      color: $mediumseagreen;
    }
    .come-true {
      color: $gray-700;
    }
    .helping-make-your-container1 {
      line-height: 72px;
      font-weight: 800;
      display: inline-block;
      max-width: 754px;
    }
    .group-child8 {
      position: absolute;
      height: 13.7%;
      width: 22.45%;
      top: 65.28%;
      right: 28.47%;
      bottom: 21.02%;
      left: 49.07%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .helping-make-your-retirement-d-group {
      position: relative;
      color: $gray-700;
    }
    .logo-reversed-no-strap-edited4 {
      position: relative;
      width: 269px;
      height: 70px;
      object-fit: cover;
    }
    .thinking-ahead-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 12px;
      text-align: left;
      color: $primary-blue-400;
    }
    .get-started {
      position: relative;
      line-height: 22px;
      font-weight: 600;
    }
    .vuesaxlineararrow-right-icon {
      position: relative;
      width: 24px;
      height: 24px;
    }
    .get-started-parent {
      border-radius: 50px;
      background: linear-gradient(90deg, #148a5f, #20b57f);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 22px 24px;
      box-sizing: border-box;
      gap: 12px;
      min-width: 250px;
      font-size: 24px;
      color: $white;
      border: 0;
      box-shadow: 0 0 24px transparent;
      transition: all ease-in-out .5s;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 24px transparentize(#148a5f, 0.45);
      }
    }
    .get-started1 {
      flex: 1;
      position: relative;
      font-weight: 600;
    }
    .group-parent16,
    .group-parent17 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .group-parent17 {
      justify-content: flex-start;
      gap: 54px;
      text-align: center;
      font-size: 24px;
    }
    .group-parent16 {
      border-radius: 30px;
      justify-content: center;
      gap: 44px;
    }
    .hello-we-are {
      font-size: 24px;
      font-weight: 500;
      color: $primary-blue-500;
      margin-bottom: 10px;
    }
    .banner-text,
    .banner-wrapper-child {
      margin: 0 !important;
      z-index: 1;
    }
    .banner-wrapper-child {
      position: absolute;
      width: 45.63%;
      top: 84px;
      right: 0.83%;
      bottom: 10.71%;
      left: 53.54%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
      z-index: 2;
    }
    .banner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      font-size: 64px;
      color: $white;
      background-image: url(../../images/avcProductDetail-CMS/banner-bg.svg);
      padding: 125px 0;
    }
    .when-you-need-container {
      position: relative;
      font-size: 48px;
      display: inline-block;
      text-align: center;
      max-width: 591px;
      color: $primary-blue-700;
    }
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
    .group-child10 {
      position: absolute;
      height: 15.53%;
      width: 28.65%;
      top: 84.47%;
      right: 56.13%;
      bottom: 0;
      left: 15.23%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .title-parent {
      position: relative;
      min-height: 190.6px;
    }
    .wrapper {
      border-radius: 20px;
      background-color: $primary-blue-400;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px 24px;
    }
    .praesent-vitae-mi {
      position: relative;
      font-size: 16px;
      line-height: 21px;
      color: $gray-800;
      display: inline-block;
      max-width: 571px;
    }
    .frame-parent27,
    .shared-cost-parent {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .shared-cost-parent {
      flex-direction: column;
      gap: 16px;
      font-size: 24px;
      color: $primary-blue-700;
    }
    .frame-parent27 {
      max-width: 690px;
      width: 100%;
      flex-direction: row;
      gap: 32px;
    }
    .frame {
      border-radius: 20px;
      background-color: $mediumseagreen;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px 24px;
    }
    .praesent-vitae-mi1,
    .praesent-vitae-mi2 {
      position: relative;
      font-size: 16px;
      line-height: 21px;
      color: $gray-800;
      display: inline-block;
      width: 562px;
    }
    .praesent-vitae-mi2 {
      width: 560px;
    }
    .easy-to-accessed-at-retirement-parent {
      width: 560px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      font-size: 24px;
      color: $primary-blue-700;
    }
    .praesent-vitae-mi3 {
      position: relative;
      font-size: 16px;
      line-height: 21px;
      color: $gray-800;
      display: inline-block;
      width: 567px;
    }
    .frame-parent26 {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .frame-parent26 {
      flex-direction: column;
      gap: 48px;
    }
    .mem-dots-rect-icon {
      position: absolute;
      height: 27.71%;
      width: 28.05%;
      top: 0;
      right: 0;
      bottom: 72.29%;
      left: 71.95%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .group-child11 {
      position: relative;
      top: 94px;
      max-height: 570px;
      object-fit: cover;
    }
    .fill-circle-icon1 {
      position: absolute;
      top: 277.4px;
      left: 146px;
      width: 68px;
      height: 68px;
      overflow: hidden;
    }
    .fill-circle-icon2,
    .fill-circle-icon3 {
      position: absolute;
      top: 155px;
      left: 495px;
      width: 32px;
      height: 32px;
      overflow: hidden;
    }
    .fill-circle-icon3 {
      top: 595.4px;
      left: 124px;
      width: 44px;
      height: 44px;
    }
    .fill-circle-icon4,
    .fill-circle-icon5 {
      position: absolute;
      top: 194px;
      left: 526px;
      width: 12px;
      height: 12px;
      overflow: hidden;
    }
    .fill-circle-icon5 {
      top: 280px;
      left: 117px;
      width: 14px;
      height: 14px;
    }
    .group-child12 {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 24px;
      background-color: $white;
      box-shadow: 0px 0px 32px -1px rgba(66, 59, 71, 0.15);
      width: 243px;
      height: 192px;
    }
    .path-2-icon,
    .path-2-icon1 {
      position: absolute;
      width: 100%;
      right: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .path-2-icon {
      height: 64.86%;
      top: 35.27%;
      bottom: -0.14%;
      opacity: 0.5;
      mix-blend-mode: normal;
    }
    .path-2-icon1 {
      height: 29.39%;
      top: 34.26%;
      bottom: 36.35%;
      mix-blend-mode: multiply;
    }
    .path-icon {
      height: 58.78%;
      width: 100%;
      top: 41.22%;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      mix-blend-mode: normal;
    }
    .path-icon,
    .path-icon1,
    .uistatus-icon {
      position: absolute;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .path-icon1 {
      height: 39.93%;
      width: 100%;
      top: 40.2%;
      right: 0;
      bottom: 19.86%;
      left: 0;
      mix-blend-mode: multiply;
    }
    .uistatus-icon {
      height: 8.11%;
      width: 5.31%;
      top: 40.54%;
      right: 18.62%;
      bottom: 51.35%;
      left: 76.07%;
    }
    .combined-shape-icon {
      position: absolute;
      left: -56.71%;
      top: -52%;
      width: 60px;
      height: 58.7px;
    }
    .div11 {
      position: absolute;
      width: 91.79%;
      top: 7.49%;
      left: 4.29%;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
    }
    .charts,
    .tooltip {
      position: absolute;
      height: 18.04%;
      width: 12.38%;
      top: 19.59%;
      right: 15.08%;
      bottom: 62.36%;
      left: 72.53%;
    }
    .charts {
      height: 89.16%;
      width: 100%;
      top: 10.84%;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .your-earning {
      position: relative;
      letter-spacing: 0.5px;
      line-height: 20px;
      font-weight: 600;
    }
    .vector-icon8 {
      position: relative;
      width: 10px;
      height: 15px;
    }
    .vector-parent4,
    .your-earning-parent {
      display: flex;
      justify-content: flex-start;
    }
    .vector-parent4 {
      flex-direction: row;
      align-items: center;
      gap: 5px;
      font-size: 18px;
      color: $primary-blue-700;
    }
    .your-earning-parent {
      position: absolute;
      top: 0;
      left: 13px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      font-size: 14px;
      color: $primary-blue-400;
      font-family: 'Inter', sans-serif;
    }
    .charts-parent {
      position: absolute;
      height: 86.46%;
      width: 93.05%;
      top: 11.46%;
      right: 3.25%;
      bottom: 2.08%;
      left: 3.7%;
    }
    .rectangle-parent8 {
      position: absolute;
      top: 65px;
      left: 0;
      width: 243px;
      height: 192px;
    }
    .mem-dots-rect-parent {
      position: relative;
      width: 656px;
      height: 664px;
      text-align: center;
      font-size: 12px;
      color: #414d55;
      font-family: 'Roboto', sans-serif;
    }
    .frame-parent25,
    .how-does-it-works {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .frame-parent25 {
      flex-direction: row;
      gap: 70px;
      font-size: 18px;
      color: $white;
    }
    .how-does-it-works {
      padding: 110px 0;
      flex-direction: column;
      gap: 44px;
    }
    .why-choose-child {
      position: relative;
      width: 1920px;
      height: 846px;
      z-index: 0;
    }
    .span {
      color: $primary-blue-700;
    }
    .an-advisor {
      margin: 0;
      color: $mediumseagreen;
    }
    .group-child13 {
      position: absolute;
      height: 15.53%;
      width: 28.65%;
      top: 84.47%;
      right: 44.62%;
      bottom: 0;
      left: 26.73%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .frame-child22 {
      position: relative;
      height: 460px;
      object-fit: cover;
      box-shadow: 0 0 32px -1px rgb(66 59 71 / 15%);
      border-radius: 32px;
    }
    .lorem-ipsum-dolor-container,
    .lorem-ipsum-dolor8 {
      position: relative;
      line-height: 28px;
      display: inline-block;
    }
    .lorem-ipsum-dolor-container {
      line-height: 26px;
    }
    .lorem-ipsum-dolor-sit-amet-co-parent,
    .lorem-ipsum-dolor-sit-amet-parent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .lorem-ipsum-dolor-sit-amet-co-parent {
      gap: 16px;
      font-size: 16px;
      color: $gray-800;
    }
    .lorem-ipsum-dolor-sit-amet-parent {
      gap: 32px;
    }
    .group-parent19,
    .more-about-us-parent {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .more-about-us-parent {
      border-radius: 50px;
      background: linear-gradient(90deg, #148a5f, #20b57f);
      width: 180px;
      justify-content: center;
      padding: 16px 24px;
      box-sizing: border-box;
      gap: 12px;
      min-width: 180px;
      text-align: center;
      font-size: 16px;
      color: $white;
      border: 0;
      box-shadow: 0 0 24px transparent;
      transition: all ease-in-out .5s;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 24px transparentize(#148a5f, 0.45);
      }
    }
    .group-parent19 {
      justify-content: flex-start;
      gap: 54px;
      font-size: 32px;
      color: $black;
    }
    .why-choose {
      background-image: url(../../images/avcProductDetail-CMS/about-bg.svg);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 65px 0;
    }
    .why-choose1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .why-choose1 {
      margin: 0 !important;
      align-items: center;
      gap: 64px;
    }
    .group-child14 {
      position: absolute;
      height: 21.83%;
      width: 28.65%;
      top: 78.17%;
      right: 28.38%;
      bottom: 0;
      left: 42.98%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .title-container {
      position: relative;
      min-width: 591px;
      min-height: 135.6px;
    }
    .frame-child24 {
      max-width: 460px;
      width: 100%;
      height: 320px;
      object-fit: cover;
      z-index: 1;
    }
    .mark-fletcher-17th {
      position: relative;
      line-height: 24px;
      font-weight: 500;
    }
    .rationally-encounter-consequen,
    .the-journey-to {
      position: relative;
      line-height: 24px;
      display: inline-block;
      max-width: 392px;
      width: 100%;
    }
    .rationally-encounter-consequen {
      font-size: 14px;
      line-height: 20px;
    }
    .mark-fletcher-17th-oct-2023-parent,
    .the-journey-to-retirement-secu-parent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .the-journey-to-retirement-secu-parent {
      gap: 8px;
      font-size: 18px;
      color: $gray-800;
    }
    .mark-fletcher-17th-oct-2023-parent {
      gap: 16px;
    }
    .read-more {
      position: relative;
      line-height: 20px;
      font-weight: 500;
      text-align: right;
      color: $gray-400;
      &:hover {
        background: linear-gradient(90deg, #148a5f, #20b57f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .frame-parent33,
    .rectangle-parent9, .frame-wrapper1 {
      display: flex;
      justify-content: flex-start;
    }
    .frame-parent33 {
      margin: 0 !important;
      flex-direction: column;
      align-items: flex-end;
      gap: 32px;
      z-index: 2;
      padding: 32px;
    }
    .rectangle-parent9 {      
      border-radius: 24px;
      background-color: $white;
      border: 1px solid $dark-purple-100;
      overflow: hidden;
    }
    .rectangle-parent9, .frame-wrapper1 {
      flex-direction: column;
      align-items: center;
      position: relative;
    }
    .frame-wrapper1,
    .rectangle-parent10 {
      flex-direction: row;
      align-items: flex-start;
    }
    .rectangle-parent10 {
      display: flex;
      justify-content: flex-start;
      position: relative;
      gap: 10px;
    }
    .blog,
    .frame-parent32 {
      display: flex;
      justify-content: flex-start;
    }
    .frame-parent32 {
      align-self: stretch;
      flex-direction: row;
      align-items: flex-start;
      gap: 30px;
      font-size: 16px;
      color: $gray-400;
    }
    .blog-section {
      position: relative;
    }
    .blog {
      flex-direction: column;
      align-items: center;
      gap: 64px;
      padding: 65px 0 75px;
    }
    .mem-dots-arrow-icon {
      position: absolute;
      top: 195px;
      left: 160px;
      width: 164px;
      height: 165px;
      overflow: hidden;
    }
    .frame-feedbacks {
      background-color: #f0f4f6;
      padding: 64px 0 84px;
    }
    .customers-feedbacks {
      position: relative;
      font-size: 48px;
      display: inline-block;
      width: 591px;
      color: $primary-blue-700;
    }
    .title3 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }
    .group-child16 {
      position: absolute;
      height: 21.83%;
      width: 28.65%;
      top: 78.17%;
      right: 23.3%;
      bottom: 0;
      left: 48.05%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
    .logo-leicester-city-council-1-icon {
      position: relative;
      width: 144px;
      height: 72px;
      object-fit: cover;
    }
    .cory-laywood-corporate,
    .its-straight-forward {
      align-self: stretch;
      position: relative;
      font-weight: 500;
    }
    .its-straight-forward {
      line-height: 24px;
      display: inline-block;
      min-height: 72px;
    }
    .cory-laywood-corporate {
      font-size: 14px;
      line-height: 20px;
      color: $gray-400;
    }
    .its-straight-forward-state-p-parent,
    .logo-leicester-city-council-1-parent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .its-straight-forward-state-p-parent {
      width: 595px;
      gap: 32px;
    }
    .logo-leicester-city-council-1-parent {
      border-radius: 24px;
      background-color: $white;
      box-shadow: 0 0 32px -1px rgba(66, 59, 71, 0.1);
      padding: 24px;
      box-sizing: border-box;
      gap: 16px;
      min-height: 280px;
    }
    .tvp-crest-col-land-mr-1-icon {
      position: relative;
      width: 263.1px;
      height: 72px;
      object-fit: cover;
    }
    .our-employees-can {
      align-self: stretch;
      position: relative;
      line-height: 24px;
      font-weight: 500;
    }
    .tvp-crest-col-land-mr-1-parent {
      border-radius: 24px;
      background-color: $white;
      box-shadow: 0 0 32px -1px rgba(66, 59, 71, 0.1);
      min-height: 280px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 24px;
      box-sizing: border-box;
      gap: 16px;
    }
    .its-straight-forward1 {
      align-self: stretch;
      position: relative;
      line-height: 24px;
      font-weight: 500;
      display: inline-block;
      height: 72px;
      flex-shrink: 0;
    }
    .logo-leicester-city-council-1-group {
      border-radius: 24px;
      background-color: $white;
      box-shadow: 0 0 32px -1px rgba(66, 59, 71, 0.1);
      flex-direction: column;
      padding: 24px;
      box-sizing: border-box;
      gap: 16px;
      min-height: 280px;
    }
    .frame-parent36,
    .logo-leicester-city-council-1-group,
    .title-wrapper-parent {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .frame-parent36 {
      flex-direction: row;
      gap: 40px;
      font-size: 18px;
      color: $primary-blue-700;
    }
    .title-wrapper-parent {
      flex-direction: column;
      gap: 64px;
    }
    .frame-feedbacks {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .vuesaxlineararrow-left-parent {
      position: absolute;
      top: 4013px;
      left: 1604px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }
    .derbyshire-county-council-vect-icon {
      width: 211px;
      height: 84px;
      object-fit: contain;
    }
    .logo-silder-child {
      border-radius: 12px;
      background-color: #fff;
      border: 1px solid #D1CFD5;
      width: 264px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo-silder {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 30px;
      padding: 64px 0 74px;
    }
    .product-detail-page {
      position: relative;
      background-color: $white;
      width: 100%;
      overflow: hidden;
      text-align: left;
      font-size: 24px;
      color: $primary-blue-400;
      font-family: 'Inter', sans-serif;
      line-height: normal;
    }
}